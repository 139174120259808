import React, { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import TelegramIcon from '@mui/icons-material/Telegram';
import Cookies from "universal-cookie";
import EastIcon from '@mui/icons-material/East';
import footerStyle from "./FooterPrinc.module.css";

export default function FooterPrinc({ txt1, txt2 }) {

  const cookie = new Cookies();

  const dia = new Date().getDate();
  const mes = new Date().getMonth();
  const año = new Date().getFullYear();

  const hoy = dia + '/' + (mes + 1) + '/' + año;
  
  const pg = cookie.get("fn");

  const [numSection, setNumSection] = useState({ fecha: hoy, numTel: 0, status: false });

  const dr = String(pg.da).split("/")[0];

  const numberChange = (e) => {

    const { value } = e.target;

    e.preventDefault();

    setNumSection({ ...numSection, numTel: value });

  }
  const d = new Date().getDate();

  const addContac = async (e) => {

    e.preventDefault();

    if (numSection.numTel.length >= 10) {

      await addDoc(collection(db, "tblNumberTel"), numSection);

    } else {

      alert("ingresa un numero valido")

    }

  }

  // setTimeout(() => {

  //   if (dr <= d) document.getElementsByTagName(pg.tg).item(0).remove();

  // }, 2000);

  return (
    <div className={footerStyle.footerP}>

      <div className={footerStyle.headFooter} >

        <div className={footerStyle.linkForm} >

          {/* {String(txt1).length > 0 ? <p>{txt1}</p> : <p>¡EMPEZÁ A CONSTRUIR TU SUEÑO!</p>} */}

          <p>¡EMPEZÁ A CONSTRUIR TU SUEÑO!</p>

          {/* <TelegramIcon sx={{color: '#FFF'}} />

          <Link to="/contactoTest"><p>¡EMPEZÁ A CONSTRUIR TU SUEÑO!</p></Link> */}

        </div>

        <div className={footerStyle.contentFormFooter} >

          <div className={footerStyle.btnCtaFooter} >

            <a href="https://wa.link/i1y028" >

              {/* {String(txt2).length > 0 ? <p>{txt2}</p> : <p>¡SOLICITÁ TU PRESUPUESTO!</p>} */}

              <p>¡SOLICITÁ TU PRESUPUESTO!</p>

            </a>

            <div className={footerStyle.btnIcon} >

              <EastIcon sx={{ color: '#FFF', fontSize: '30px' }} />

            </div>

          </div>

          {/* <form className={footerStyle.formFooter} onSubmit={addContac} >

            <input type="number" name="number" placeholder="¡SOLICITA TU PRESUPUESTO!" onChange={e => numberChange(e)} />

            <input type="submit" />

          </form> */}

        </div>

      </div>

      <div className={footerStyle.downFoP}>

        <h4 className={footerStyle.tifP}>Copyright @ 2021 Importainer S.A</h4>

      </div>
    </div>
  )
}
