import React from "react";
import EntregasCardSt from "./EntregasCardSt.module.css"

export default function EntregasCard({ imgLink, title, subTitle, proximamente }) {

    // console.log(imgLink, 'ooooo')

    // setTimeout(() => {

    //     if (proximamente === true) {
    //         // console.log(document.getElementsByClassName(EntregasCardSt.cardContent).item(1), 'iiiiii')
    //         document.getElementsByClassName(EntregasCardSt.EntProx).item(1).style.visibility = 'visible';

    //     }

    // }, 500)

    return (

        <div className={EntregasCardSt.cardContent} >

            { proximamente && <div className={EntregasCardSt.EntProx} >PRÓXIMAMENTE</div> }

            <div className={EntregasCardSt.imgCard} >

                <img src={imgLink} />

            </div>

            <div className={EntregasCardSt.contentText} >

                <h3>{title}</h3>

                <h5>{subTitle}</h5>

            </div>

        </div>

    )

}