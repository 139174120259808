import React from "react";
import { Link } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import CallActive1St from "./CallActive1St.module.css";

export default function CallActive1({ txtBtn }) {

    return (

        <div className={CallActive1St.CallActive1Content} >

            {/* <Link to="/contactoTest" ><p>{txtBtn}</p></Link> */}

            <a className={CallActive1St.CallActive1A} href="https://wa.link/i1y028" >

                <p>{txtBtn}</p>

            </a>

            <div className={CallActive1St.boxSend} >

                <SendIcon sx={{ color: "rgba(255, 55, 55, 0.8)" }} />

            </div>

        </div>

    )

}