import React from "react";
import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import NotFound from "../pages/NotFound";
import RouteUserTemp from "./RouteDefined/RoutUserTemp";
import RouteSecond from "./RouteDefined/RouteSecond";

// ===== ADMIN =====

import Login from "./Admin/LoginIn/LoginIn";
import IndexAdm from "./Admin/IndexAdm/IndexAdm";
import LoginCreate from "./Admin/LoguinCreate/LoginCreate";
import CreatePub from "./Admin/CreatePub/CreatePub";
import ListPub from "./Admin/ListPublic/ListPublic";
import EditarPub from "./Admin/EditarPub/EditarPub";
import LoginUser from "./Admin/ListUser/ListUser";
import UserDetail from "./Admin/UserDetail/UserDetail";
import ReservasADM from "./Admin/Reservas/Reservas";
import CreateTestimony from "./Admin/CreateTestimony/CreateTestimony";
import TestimonyList from "./Admin/TestimonyList/TestimonyList";
import Consultas from "./Admin/Consultas/Consultas";
import CreateProducts from "./Admin/CreateProducts/CreateProducts";
import Encuesta from "./Admin/Encuesta/Encuesta";
import EncuestaVentas from "./Admin/EncuestaVentas/EncuestaVentas";

import Home from "../pages/Home";
import ContactoPage2 from "../pages/ContactoPage";
import ProyectosNew from "../pages/Proyectos";
import UserTemp from "../components/UserTemp/UserTemp";
import LogOut from "../components/UserTemp/logOut";
import ProductGroup from "../components/ProductGroup/ProductGroup";
import AllEntregas from "../components/AllEntregas/AllEntregas";
import EntDesc from "../components/EntDesc/EntDesc";
import FichaTecInd from "../components/FichaTecInd/FichaTecInd";
import PagoDetail from "../components/PagoDetail/PagoDetail";
import SisConst from "../components/SisConst/SisConst";

// ===== USER =====

const App = () => (

  <>
 
    <HashRouter>
      
      <Switch>

        {/* ===== URL NUEVAS ===== */}
        <Route exact path="/" component={Home} />
        <Route exact path="/userTemp" component={UserTemp} />
        <Route path="/contacto" component={ContactoPage2} />
        <Route path="/proyectosNew" component={ProyectosNew} />
        <Route path="/sistemaConstructivo" component={SisConst} />
        <Route path="/EntregasCategory" component={AllEntregas} />
        <Route path="/Proyectos/:id" component={ProductGroup} />
        <Route path="/Entregas/:id" component={EntDesc} />
        <Route path="/PagoProducts" component={PagoDetail} />
        
        <Route path="/detailProduct/:id" component={FichaTecInd} />
        {/* ===== URL NUEVAS ===== */}

        {/* ===== ADMINISTACION ===== */}
        <Route exact path="/admin" component={Login} />
        <Route exact path="/Encuesta" component={Encuesta} />
        <Route exact path="/EncuestaVentas" component={EncuestaVentas} />

        <RouteSecond path="/indexAdm" component={IndexAdm} />
        <RouteSecond path="/LoginCreate" component={LoginCreate} />
        <Route exact path="/LoginCreatePrueba" component={LoginCreate} />
        {/* RouteAdm RUTAS DE ACCESO ADMINISTRACION */}
        <RouteSecond path="/users" component={LoginUser} />
        <RouteSecond path="/UserDetail" component={UserDetail} />
        {/* RouteMark RUTAS DE ACCESO MARKETING */}
        <RouteSecond path="/CreatePub" component={CreatePub} />
        <RouteSecond path="/ListaPublicaciones" component={ListPub} />
        <RouteSecond path="/EditarPub" component={EditarPub} />
        <RouteSecond path="/ReservasADM" component={ReservasADM} />
        <RouteSecond path="/TestimonyList" component={TestimonyList} />
        <RouteSecond path="/CreateTestimony" component={CreateTestimony} />
        <RouteSecond path="/ConsultasADM" component={Consultas} />
        <RouteSecond path="/CreateProducts" component={CreateProducts} />

        <Route component={NotFound} />
      </Switch>
    </HashRouter>

  </>
  
);

export default App;
