import React from "react";
import ServiciosCardSt from "./ServiciosCardSt.module.css";

export default function ServiciosCard({title, parraf, icon}) {

    return (

        <div className={ServiciosCardSt.ContainerCard} >

            <img src={icon} />

            <h3>{title}</h3>

            <p>{parraf}</p>

        </div>

    )

}