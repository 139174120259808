import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
// import { setProyectSelect } from "../../Redux/Actions/index";
import BannerSlideFilterOff from "./BannerSlideFilterOff.module.css";

export default function BannerSlide({ img }) {

  const dispatch = useDispatch();

  // const ofertState = useSelector(state => state.ofertState);

  return (

    <div className={BannerSlideFilterOff.BannerSlide}>

      <img className={BannerSlideFilterOff.imgStyle} src={img} alt="" />

    </div>

  );

}
