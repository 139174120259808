import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import BannerFinal from "../Banner/BannerFinal";
import BannerSlideFilterOff from "../Banner/bannerFilterOff/BannerSlideFilterOff";
import BtnVerMasConsult from "../BtnVerMasConsult/BtnVerMasConsult";
import ListLink from "../ListLink/ListLink";
import { getAllProyect, getAllProducts, getProyectGoup, setOfertStatus, setFilterEnt, setProyectSelect, setFilterGroup } from "../../Redux/Actions/index";
import StyleProyectCards from "./ProyectosCardsStyle.module.css";
import { set } from "firebase/database";

export default function ProyectosCards({ tipoLanding, title, txtDesc, image, tipo, linkT, btnFirs, btnSecond, dispatch1, dispatch2, dispatch3, id, timeOn, lista }) {

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    const [active, setActive] = useState(true);

    const disProyect = () => {

        dispatch(getProyectGoup(tipo));

    }

    const OptionResult = () => {
        // console.log(tipo, 'kkk')
        switch (tipoLanding) {

            case "proyect":

                return (

                    <Link to={{
                        pathname: `/Proyectos/${title}`,
                        state: { tipo }
                    }} ><div className={StyleProyectCards.btnVer} onClick={e => { dispatch(setProyectSelect(tipo)) }} >{"VER TODOS"}</div></Link>

                )

            case "testi":

                return (

                    <Link to={linkT} ><div className={StyleProyectCards.btnVer} onClick={e => { dispatch(setFilterEnt(id)) }} >{"VER ENTREGA"}</div></Link>

                )

            default:
                break;
        }

    }

    const verMasF = () => {

        document.getElementsByClassName(StyleProyectCards.contentTxtDesc)[id - 1].classList.toggle(StyleProyectCards.contentTxtDescActive);

        document.getElementsByClassName(StyleProyectCards.verMas)[id - 1].classList.toggle(StyleProyectCards.verMasClose)

        setTimeout(() => {

            document.getElementsByClassName(StyleProyectCards.bodyProyectCard)[id - 1].classList.toggle(StyleProyectCards.contentTxtDescActive)

        }, 500);

    }

    setTimeout(() => {

        if (tipo === "casaContainer") {

            document.getElementsByClassName(StyleProyectCards.contentProyectsCards).item(0).classList.add(StyleProyectCards.extra)

        }

        const childrenContent = document.getElementsByClassName(StyleProyectCards.contentProyectsCards)

        childrenContent[1].classList.add(StyleProyectCards.stylePlus);

        childrenContent[2].classList.add(StyleProyectCards.stylePlus);

        childrenContent[3].classList.add(StyleProyectCards.stylePlus);

    }, 500);

    // console.log(image[0], 'iiii')

    return (

        <div className={StyleProyectCards.contentProyectsCards} >

            <div className={StyleProyectCards.contentImg} >

                {

                    image.length > 1 ?

                    <BannerFinal timeTransition="2000" timeFrecuencia={4000} timeOn={timeOn} medHeigth="100%" >

                        {

                            image.map((e, i) => {

                                return <BannerSlideFilterOff
                                    key={i}
                                    id={i}
                                    img={e}

                                />

                            })

                        }

                    </BannerFinal> 

                    :

                    <img src={image[0]} />

                }

                {

                    tipo === "casaContainer" ?



                        <div className={StyleProyectCards.ContentListCard} >

                            <ListLink tipo1={tipo} />

                        </div> : null

                }

            </div>

            <div className={StyleProyectCards.bodyProyectCard} >

                <h2>{String(title).toUpperCase()}</h2>

                <p className={StyleProyectCards.contentTxtDesc} >

                    {txtDesc}

                </p>

                <div className={StyleProyectCards.verMas} onClick={verMasF} ></div>

                {

                    tipo != "casaContainer" ?



                        <div className={StyleProyectCards.contentBTN} >

                            <BtnVerMasConsult pathname={`/Proyectos/${title}`} tipo={tipo} />

                        </div> : null

                }

            </div>

        </div>

    )

}