import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ListLinkSt from "./ListLinkSt.module.css";

export default function ListLink({ pathname1, tipo1, pathname2, tipo2, pathname3, tipo3 }) {

    const classic = useSelector(state => state.classic);
    const comfort = useSelector(state => state.comfort);
    const premium = useSelector(state => state.premium);

    const timeAnimation = () => {

        const firstElement = document.getElementsByClassName(ListLinkSt.ContentCard)?.item(0);

        const secondElement = document.getElementsByClassName(ListLinkSt.ContentCard)?.item(1);

        const treeElement = document.getElementsByClassName(ListLinkSt.ContentCard)?.item(2);

        setInterval(() => {

            firstElement?.classList.add(ListLinkSt.animation1)

            secondElement?.classList.add(ListLinkSt.animation2)

            treeElement?.classList.add(ListLinkSt.animation3)

        }, 1000);

        setInterval(() => {

            firstElement.classList.remove(ListLinkSt.animation1)

            secondElement.classList.remove(ListLinkSt.animation2)

            treeElement.classList.remove(ListLinkSt.animation3)

        }, 6000);

    }

    timeAnimation()

    setTimeout(() => {

        document.getElementsByClassName(ListLinkSt.ContentCard)?.item(0).addEventListener("hover", () => {

            // document.getElementsByClassName(ListLinkSt.ContentCard)?.item(0).classList.toggle(ListLinkSt.HoverEffect);
            console.log('uuuu')

        })

    }, 500);

    return (

        <div className={ListLinkSt.contentListGroup} >

            <Link to={{
                pathname: `/Proyectos/CLASSIC`,
                state: { tipo1 }
            }} >

                <div className={ListLinkSt.ContentCard} >
                    {/* 
                    <div className={ListLinkSt.contentImgBack} >

                        <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/proyectosCarrusel%2Fduplex-45m2-importainer.JPG?alt=media&token=7238c538-b5b1-40e4-b91c-dc483ec031a8" />

                    </div> */}

                    <div className={ListLinkSt.contentTextLink} >

                        <h3>CLASSIC</h3>

                        <p>

                            Nuestra línea classic te ofrece la oportunidad de adquirir tu casa container
                            lista para habitar al mejor precio del mercado. <b>Hacé click y descubrila.</b>

                        </p>

                    </div>

                </div>

            </Link>

            <Link to={{
                pathname: `/Proyectos/COMFORT`,
                state: { tipo1 }
            }} >

                <div className={ListLinkSt.ContentCard} >

                    <div className={ListLinkSt.contentTextLink} >

                        <h3>COMFORT</h3>

                        <p>

                            Nuestra línea comfort te ofrece la oportunidad de adquirir tu casa container
                            lista para habitar, y con terminaciones de calidad. <b>Hacé click y descubrila.</b>

                        </p>

                    </div>

                </div>

            </Link>

            <Link to={{
                pathname: `/Proyectos/PREMIUM`,
                state: { tipo1 }
            }} >

                <div className={ListLinkSt.ContentCard} >

                    <div className={ListLinkSt.contentTextLink} >

                        <h3>PREMIUM</h3>

                        <p>

                            Nuestra línea premium cuenta con diseños exclusivos, personalizados,
                            con revestimientos en placa cementicia, pisos en porcelanato y mobiliario premium. <b>Hacé click y descubrila.</b>

                        </p>

                    </div>

                </div>

            </Link>

            {/* {

                classic.length > 0 &&

                <Link to={{
                    pathname: `/Proyectos/CLASSIC`,
                    state: { tipo1 }
                }} >

                    <div className={ListLinkSt.ContentCard} >

                        <h3>CLASSIC</h3>

                        <p>Nuestra línea classic, te ofrece la oportunidad de adquirir tu casa container,lista para habitar  al mejor precio del mercado.</p>

                    </div>

                </Link>

            }

            {

                comfort.length > 0 &&

                <Link to={{
                    pathname: `/Proyectos/COMFORT`,
                    state: { tipo1 }
                }} >

                    <div className={ListLinkSt.ContentCard} >

                        <h3>COMFORT</h3>

                        <p>Nuestra línea comfort, te ofrece la oportunidad de adquirir tu casa container, lista para habitar, y con terminaciones de calidad.</p>

                    </div>

                </Link>

            }

            {

                premium?.length > 0 &&

                <Link to={{
                    pathname: `/Proyectos/PREMIUM`,
                    state: { tipo1 }
                }} >

                    <div className={ListLinkSt.ContentCard} >

                        <h3>PREMIUM</h3>

                        <p>Nuestra línea premium, cuenta con diseños exclusivos,personalizados, con revestimientos en placa cementicia, pisos en porcelanato y mobiliario premium. </p>

                    </div>

                </Link>

            } */}

        </div>

    )

}