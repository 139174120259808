import React from "react";
import ServiciosCard from "../ServiciosCard/ServiciosCard";
import CallActive1 from "../CallActive/CallActive1/CallActive1";
import CallActive2 from "../CallActive/CallActive2/CallActive2";
import ServiciosSt from "./ServiciosSt.module.css";

export default function Servicios() {

    return (

        <div className={ServiciosSt.ContentGralServis} >

            <h2>¡DISFRUTÁ NUESTROS SERVICIOS!</h2>

            <div className={ServiciosSt.ServisContainer} >

                <ServiciosCard
                    title="MANO DE OBRA"
                    parraf="Contamos con un equipo en fábrica altamente capacitado en construcciones modulares."
                    icon="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/imgTemp%2Fobrero.png?alt=media&token=9893224f-3a9b-4ed2-ab33-44489aa93139"
                />

                <ServiciosCard
                    title="ENTREGA E INSTALACIÓN EN TODO EL PAÍS"
                    parraf="Transportamos e instalamos tu proyecto modular, desde nuestra fábrica, hasta tu terreno."
                    icon="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/imgTemp%2Fgrua-de-carga.png?alt=media&token=29bc3e3f-624a-4200-b587-c97ddfa2f86f"
                />

                <ServiciosCard
                    title="ASESORÍA PERSONALIZADA"
                    parraf="Contamos con ejecutivos comerciales que atenderán a todas tus consultas, y te acompañarán en el proceso de compra de tu proyecto container ¡Ideal!"
                    icon="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/imgTemp%2Foperador.png?alt=media&token=44387645-6850-4f0d-a655-4b60d48879b5"
                />

            </div>

            <div className={ServiciosSt.btnActionServ} >

                <CallActive2 txtBtn="¡CONSULTANOS!" />

                {/* <CallActive1 txtBtn="¡CONSULTANOS!" /> */}

            </div>

        </div>

    )

}