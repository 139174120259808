import React from "react";
import { Link } from "react-router-dom";
import CallActive3St from "./CallActive3St.module.css";

export default function CallActive3({ link, text, modLink }) {

    return (

        <>

            {

                modLink === true ?

                    <a className={CallActive3St.btnConstA} href={link} >

                        <div className={CallActive3St.btnConst} >

                            <div className={CallActive3St.btnConstHijo1} ></div>

                            <div className={CallActive3St.btnConstHijo2} ></div>

                            <div className={CallActive3St.btnConstHijo3} ></div>

                            <div className={CallActive3St.btnConstHijo4} ></div>

                            <p>{text}</p>

                        </div>

                    </a> :

                    <Link to={link} style={{ color: '#000', textDecoration: 'none'}} >

                        <div className={CallActive3St.btnConst} >

                            <div className={CallActive3St.btnConstHijo1} ></div>

                            <div className={CallActive3St.btnConstHijo2} ></div>

                            <div className={CallActive3St.btnConstHijo3} ></div>

                            <div className={CallActive3St.btnConstHijo4} ></div>

                            <p>{text}</p>

                        </div>

                    </Link>

            }

        </>

    )

}