import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import EntregasCard from "../EntregasCard/EntregasCard";
import { setFilterEnt } from "../../Redux/Actions/index";
import EntregasContainersSt from "./EntregasContainersSt.module.css";

export default function EntregasContainers() {

    const dispatch = useDispatch();

    const allEntregas = useSelector(state => state.allEntregas)

    // console.log(allEntregas, 'uuu')

    return (

        <div className={EntregasContainersSt.boxGral} >

            <h2>ENTREGAS Y TESTIMONIOS</h2>

            <div className={EntregasContainersSt.containerEnt} >

                {

                    allEntregas?.filter((f, j) => j < 8).map((e, i) => {
                        // console.log(e.proximamente, 'iiiii')
                        return !e.proximamente ? <Link to={`/Entregas/${e.id}`} >

                            <div onClick={j => { dispatch(setFilterEnt(e.id)) }} >

                                <EntregasCard imgLink={e.img[0]} title={e.title} subTitle={e.subTitle} key={i} proximamente={e.proximamente} />

                            </div>

                        </Link> :

                            <div onClick={j => { dispatch(setFilterEnt(e.id)) }} >

                                <EntregasCard imgLink={e.img[0]} title={e.title} subTitle={e.subTitle} key={i} proximamente={e.proximamente} />

                            </div>

                    })

                }

            </div>

        </div>

    )

}