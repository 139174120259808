import React from "react";
import NosotrosSt from "./NosotrosSt.module.css";

export default function Nosotros() {

    return (

        <div className={NosotrosSt.container} >

            <div className={NosotrosSt.contentImg} >

                <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/imgGral%2Ffrente-fabrica-importainer.JPG?alt=media&token=b8019cb5-e884-4200-a5a7-6776fbd181de" />

            </div>

            <div className={NosotrosSt.content} >

                <h2>ACERCA DE IMPORTAINER S.A</h2>

                <p>Somos la empresa #1 en la república de Argentina, especializada en construcciones a base de containers marítimos. Con más de 10 años de experiencia en el mercado, nos dedicamos a ser la solución ideal para cada persona que cuenta con terreno propio y sueña con realizar la casa, pileta, quincho; oficina y local comercial de sus sueños.</p>

            </div>

        </div>

    )

}