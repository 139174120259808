import React from "react";
import NavBarSec from "../NavBarSec/NavBarSec";
import CallActive3 from "../CallActive/CallActive3/CallActive3";
import Footer from "../Footer/Footer";
import SisConstSt from "./SisConstSt.module.css";

export default function SisConst() {

    setTimeout(() => {

        document.getElementsByClassName(SisConstSt.containerConstCont).item(0).classList.add(SisConstSt.containerConstContActive);

    }, 500)

    window.addEventListener("scroll", () => {

        const alt = (window.innerHeight / 3) * 2;

        const ele1 = document.getElementsByClassName(SisConstSt.contImgVent).item(0);

        const ele2 = document.getElementsByClassName(SisConstSt.constText).item(0);

        if (alt > ele1.getBoundingClientRect().top) {

            document.getElementsByClassName(SisConstSt.contImgVent).item(0).classList.add(SisConstSt.contImgVentActive);

            document.getElementsByClassName(SisConstSt.contVenText).item(0).classList.add(SisConstSt.contVenTextActive);

        }

        if (alt > ele2.getBoundingClientRect().top) {

            document.getElementsByClassName(SisConstSt.constText).item(0).classList.add(SisConstSt.constTextActive);

            document.getElementsByClassName(SisConstSt.contListConst).item(0).classList.add(SisConstSt.contListConstActive);

        }

    })

    return (

        <div>

            <NavBarSec />

            <div className={SisConstSt.contentBaner} >

                <div className={SisConstSt.imgBanner} >

                    <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Fbanner-sistema-constructivo-importainer.jpg?alt=media&token=b9d70883-2ad1-4e4f-bce2-db8006ea4709" />

                </div>

                <h2>SISTEMA CONSTRUCTIVO</h2>

            </div>

            <div className={SisConstSt.containerConstCont} >

                <div className={SisConstSt.contImg} >

                    <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/ventajas%2Fventajas01.jpg?alt=media&token=90b72f67-3fe1-4705-8b44-f7635f4fe98c" />

                </div>

                <div className={SisConstSt.constConteText} >

                    <h1>¿CONSTRUCCIÓN CON CONTENEDORES?</h1>

                    <p>

                        La construcción con contenedores es tendencia por su arquitectura modular completamente sustentable,
                        y rapidez constructiva. La optimización de recursos en la utilización de materiales, maquinaria,
                        y mano de obra en general,  hace de esta una alternativa muy atractiva para aquellas personas que cuentan con un terreno
                        y buscan una solución habitacional más económica y cómoda.

                    </p>

                    <div className={SisConstSt.contBtnAc} >

                        <CallActive3

                            link="https://api.whatsapp.com/send/?phone=5491122763820&text&type=phone_number&app_absent=0"
                            text="CONTACTANOS"
                            modLink={true}

                        />

                    </div>

                </div>

            </div>

            <div className={SisConstSt.contVentajas} >

                <div className={SisConstSt.contImgVent} >

                    <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/ventajas%2Fventajas02.jpg?alt=media&token=ef4d845e-f204-4910-addc-ba40d141a77c" />

                </div>

                <div className={SisConstSt.contVenText} >

                    <h3>VENTAJAS</h3>

                    <div>

                        <ul className={SisConstSt.contListVent} >

                            <li>

                                <div className={SisConstSt.iconTem} >

                                    <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2FRAPIDEZ%20CONSTRUCTIVA.png?alt=media&token=356809d8-b031-4899-83cc-76a63034a4c1" />

                                </div>

                                <div className={SisConstSt.contTextVent} >

                                    <h3>RAPIDEZ CONSTRUCTIVA</h3>

                                    <p>Construcción 50% más rápida que una obra tradicional.</p>

                                </div>

                            </li>

                            <li>

                                <div className={SisConstSt.iconTem} >

                                    <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2FCOSTO%203.png?alt=media&token=3dbdda9d-799e-446c-8b05-f1b6d1b2a0ac" />

                                </div>

                                <div className={SisConstSt.contTextVent} >

                                    <h3>RENTABILIDAD EN COSTOS</h3>

                                    <p>Coste inferior a una construcción tradicional.</p>

                                </div>

                            </li>

                            <li>

                                <div className={SisConstSt.iconTem} >

                                    <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2FPERSONALIZADO..png?alt=media&token=94fa1719-531b-469f-aedf-0edc88112b05" />

                                </div>

                                <div className={SisConstSt.contTextVent} >

                                    <h3>SISTEMA MODULAR TRANSPORTABLE</h3>

                                    <p>Posibilidad de mover tu proyecto e instalarlo en distintas locaciones.</p>

                                </div>

                            </li>

                            <li>

                                <div className={SisConstSt.iconTem} >

                                    <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2FPERSONALIZADO%202.png?alt=media&token=a42c5d75-17f0-44cb-b6d8-37357bf766cf" />

                                </div>

                                <div className={SisConstSt.contTextVent} >

                                    <h3>DISEÑO PERSONALIZADO E ILIMITADO</h3>

                                    <p>Oportunidad de diseñar y ampliar tu proyecto las veces que quieras por medio de la unión de dos o más contenedores.</p>

                                </div>

                            </li>

                        </ul>

                    </div>

                </div>

            </div>

            <div className={SisConstSt.contConstImp} >

                <div className={SisConstSt.constText} >

                    <h2>¿POR QUÉ ELEGIRNOS?</h2>

                    <p>

                        En importainer contamos con más de 10 años de experiencia en el rubro de la construcción a base de contenedores marítimos.
                        Trabajamos con los mejores componentes del mercado, por lo que garantizamos una alta durabilidad en cada uno de nuestros proyectos.
                        Todos nuestros containers están acondicionados con Lana de Vidrio y Aluminio para proporcionar una excelente
                        y óptima aislación termo-acústica que evite altas y bajas temperaturas y/o ruidos no deseados dentro del módulo.
                        Además, fabricamos desde viviendas hasta piletas, locales, quinchos, bares y mucho más proyectos por medio del reciclado de contenedores
                        de 20 y 40 pies disponibles para ser transformados en espacios completamente habitables.

                    </p>

                    <div>

                        <CallActive3

                            link="/contacto"
                            text="SOLICITAR PRESUPUESTO"
                            modLink={false}

                        />

                    </div>

                </div>

                <div className={SisConstSt.contListConst} >

                    <div>

                        <div className={SisConstSt.iconTem2} >

                            <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2Ffinanciaci%C3%B3n.png?alt=media&token=ff4dd35f-80db-406d-8921-8e53287b9071" />

                        </div>

                        <div className={SisConstSt.contConstText} >

                            <h3>FINANCIACIÓN EN PESOS</h3>

                            <p>Posibilidad de empezar a construir tu obra completamente financiada por nosotros.</p>

                        </div>

                    </div>

                    <div>

                        <div className={SisConstSt.iconTem2} >

                            <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2Ficons8-cash-64.png?alt=media&token=9d31a01f-f3d2-435e-861b-315d8cbcf8f3" />

                        </div>

                        <div className={SisConstSt.contConstText} >

                            <h3>PAGO EN CUOTAS</h3>

                            <p>Accedé a nuestros planes de pago de hasta 240 cuotas.</p>

                        </div>

                    </div>

                    <div>

                        <div className={SisConstSt.iconTem2} >

                            <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2Ficons8-home-64.png?alt=media&token=6df5cb10-dea5-4ac1-a8e0-f0491506aa72" />

                        </div>

                        <div className={SisConstSt.contConstText} >

                            <h3>SISTEMA LLAVE EN MANO</h3>

                            <p>Entregamos tu proyecto listo para empezar a disfrutarlo.</p>

                        </div>

                    </div>

                    <div>

                        <div className={SisConstSt.iconTem2} >

                            <img src="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/icon%2Ficons8-container-truck-50.png?alt=media&token=62b76122-f370-4b36-bbb6-56e5eb1e1e55" />

                        </div>

                        <div className={SisConstSt.contConstText} >

                            <h3>TRASLADO E INSTALACIÓN</h3>

                            <p>Transportamos y ensamblamos en tu terreno</p>

                        </div>

                    </div>

                </div>

            </div>

            <Footer />

        </div>

    )

}