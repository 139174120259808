import {

    GET_ALL_PROYECT,
    GET_ALL_PRODUCTS,
    GET_PROYECT_GROUP,
    GET_ALL_ASPECONST,
    GET_ALL_ENTREGAS,
    GET_FILTER_TYPE,
    GET_FILTER_STATUS,
    GET_ALL_BANER,
    SET_OFERT_STATUS,
    SET_FILTER_OFERT,
    SET_STATUS_OFERT,
    SET_FILTER_ENT,
    SET_PROYECT_SELECT,

} from "../Actions/actionType";

const initialState = {

    proyect: [],
    allProducts: [],
    proyectGroup: [],
    ofertState: true,
    ofertFilter: [],
    aspeConst: [],
    allEntregas: [],
    entregasFilter: [],
    casaContainer: [],
    duplex: [],
    combo: [],
    exteriores: [],
    emprendimiento: [],
    oficina: [],
    casaCampo: [],
    tiny: [],
    garage: [],
    proyectSelect: null,
    filterGroup: [],
    productsFilterStatus: [],
    classic: [],
    comfort: [],
    premium: [],
    banner: [],

};

export default function rootReducer(state = initialState, action) {

    switch (action.type) {

        case GET_ALL_PROYECT:

            return {

                ...state,
                proyect: action.payload.filter(e => e.proyectStatus === true )

            }

        case GET_ALL_PRODUCTS:

            return {

                ...state,
                allProducts: action.payload

            }

        case GET_PROYECT_GROUP:

            // let proyectGroupOrderBy = action.payload.sort((a,b) => parseInt(a.priceDesc) - parseInt(b.priceDesc))

            // let ej1 = state.allProducts.sort((a, b) => parseInt(a.sup) - parseInt(b.sup)).filter(e => e.tipo === action.payload)

            return {

                ...state,
                proyectGroup: state.productsFilterStatus.sort((a, b) => parseInt(a.priceDesc) - parseInt(b.priceDesc)).filter(e => e.tipo === action.payload)

            }

        case GET_ALL_ASPECONST:

            return {

                ...state,
                aspeConst: action.payload

            }

        case GET_ALL_ENTREGAS:

            return {

                ...state,
                allEntregas: action.payload

            }

        case GET_FILTER_TYPE:

            const casaForSup = state.productsFilterStatus.filter(e => e.tipo === "casaContainer").sort((a, b) => parseInt(a.sup) - parseInt(b.sup))

            return {

                ...state,
                casaContainer: casaForSup.filter(e => e.lista === "CLASSIC").concat(casaForSup.filter(e => e.lista === "COMFORT")).concat(casaForSup.filter(e => e.lista === "PREMIUM")),
                duplex: state.productsFilterStatus.filter(e => e.tipo === "duplex").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                combo: state.productsFilterStatus.filter(e => e.tipo === "combo").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                exteriores: state.productsFilterStatus.filter(e => e.tipo === "exteriores").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                emprendimiento: state.productsFilterStatus.filter(e => e.tipo === "emprendimiento").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                oficina: state.productsFilterStatus.filter(e => e.tipo === "oficina").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                casaCampo: state.productsFilterStatus.filter(e => e.tipo === "casaCampo").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                tiny: state.productsFilterStatus.filter(e => e.tipo === "tiny").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                garage: state.productsFilterStatus.filter(e => e.tipo === "garage").sort((a, b) => parseInt(a.sup) - parseInt(b.sup)),
                classic: casaForSup.filter(e => e.lista === "CLASSIC"),
                comfort: casaForSup.filter(e => e.lista === "COMFORT"),
                premium: casaForSup.filter(e => e.lista === "PREMIUM"),


            }

        case GET_FILTER_STATUS:

            return {

                ...state,
                productsFilterStatus: state.allProducts.filter(e => e.statusActive === true)

            }

        case GET_ALL_BANER:

            return {

                ...state,
                banner: action.payload

            }

        case SET_OFERT_STATUS:

            return {

                ...state,
                ofertState: action.payload

            }

        case SET_FILTER_OFERT:

            return {

                ...state,
                ofertFilter: state.productsFilterStatus.filter(e => e.ofert === true)

            }

        case SET_STATUS_OFERT:

            return {

                ...state,
                ofertState: action.payload
            }

        case SET_FILTER_ENT:

            return {

                ...state,
                entregasFilter: state.allEntregas.filter(e => e.id === action.payload)

            }

        case SET_PROYECT_SELECT:
            console.log('iiiifff')
            return {

                ...state,
                proyectSelect: action.payload

            }

        default:

            return state;

    }

}
