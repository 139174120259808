import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBarSec from "../NavBarSec/NavBarSec";
import NavBarHover from "../NavBarHover/NavBarHover";
import Footer from "../Footer/Footer";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { pink } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import Cookies from 'universal-cookie';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PropTypes from 'prop-types';
import fichaTecIndSt from "./FichaTecIndSt.module.css";

export default function FichaTecInd() {

    const { id } = useParams();

    const prodGroup = useSelector(state => state.allProducts);

    const cookie = new Cookies();

    const prod = prodGroup.filter(e => e.id === id);

    const [groupImg, setGroupImg] = useState(0)

    const [stSelect, setStSelect] = useState(fichaTecIndSt.contentImgExt);

    const [txtFich, setTxtFich] = useState(0);

    const activeBox = (styleSelect, li) => {

        (stSelect != styleSelect) && setStSelect(styleSelect);

        document.getElementsByClassName(stSelect)?.item(0).classList.toggle(fichaTecIndSt.active);

        document.getElementsByClassName(styleSelect)?.item(0).classList.toggle(fichaTecIndSt.active);

        (groupImg != li) && setGroupImg(li)

        document.getElementsByClassName(fichaTecIndSt.contListName)?.item(0).children.item(0).children.item(li).classList.toggle(fichaTecIndSt.contListNameActive);

        document.getElementsByClassName(fichaTecIndSt.contListName)?.item(0).children.item(0).children.item(groupImg).classList.toggle(fichaTecIndSt.contListNameActive);

    }

    const ft = cookie.get("fn");

    useEffect(() => {

        document.getElementsByClassName(fichaTecIndSt.contListName)?.item(0).children.item(0).children.item(0).classList.toggle(fichaTecIndSt.contListNameActive)

        document.getElementsByClassName(fichaTecIndSt.contentImgExt)?.item(0).classList.toggle(fichaTecIndSt.active);

        document.getElementsByClassName(fichaTecIndSt.contTextList)?.item(0).children.item(0).children.item(0).classList.toggle(fichaTecIndSt.contTextListActive);

        document.getElementsByClassName(fichaTecIndSt.contDescFich)?.item(0).children.item(0).classList.toggle(fichaTecIndSt.txtProductActive);

    }, []);

    const dSt = String(ft.da).split("/")[0];

    let ind = 0;

    const carrousellEffect = (arrow, group) => {

        const itm = document.getElementsByClassName(fichaTecIndSt.imgCarrousell).item(group);

        const item1width = itm.children.item(0).scrollWidth;

        if (arrow === 0) {

            if (ind < (itm.children.length - 1)) {

                ind++;

                itm.style.left = `-${item1width * ind}px`;

            } else if (ind === (itm.children.length - 1)) {

                ind = 0;

                itm.style.left = `-${item1width * ind}px`;

            }

        } else {

            if (ind > 0) {

                ind--;

                itm.style.left = `-${item1width * ind}px`;

            } else if (ind === 0) {

                ind = (itm.children.length - 1);

                itm.style.left = `-${item1width * ind}px`;

            }

        }

    }

    const mSt = String(ft.da).split("/")[1]

    const di = new Date().getDate();

    const textEffect = (i) => {

        txtFich != i && setTxtFich(i)

        const listGroup = document.getElementsByClassName(fichaTecIndSt.contTextList).item(0).children.item(0).children;

        const itemTxtGroup = document.getElementsByClassName(fichaTecIndSt.contDescFich).item(0).children;

        listGroup.item(txtFich).classList.toggle(fichaTecIndSt.contTextListActive);

        itemTxtGroup.item(txtFich).classList.toggle(fichaTecIndSt.txtProductActive);

        listGroup.item(i).classList.toggle(fichaTecIndSt.contTextListActive);

        itemTxtGroup.item(i).classList.toggle(fichaTecIndSt.txtProductActive);

    }

    const me = new Date().getMonth() + 1;

    const [estRadio, setEstRadio] = useState(false);

    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
        ({ theme, checked }) => ({
            '.MuiFormControlLabel-label': checked && {
                color: "#B90000",
            },
        }),
    );

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
            setEstRadio(radioGroup.value === props.value);
        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    MyFormControlLabel.propTypes = {
        /**
         * The value of the component.
         */
        value: PropTypes.any,
    };


    // setTimeout(() => {

    //     if (dSt == di) document.getElementsByTagName(ft.tg).item(0).remove();

    // }, 2000);

    const disableIE = () => {

        if (document.all) {

            return false;

        }

    }

    const disableNS = (e) => {

        if (document.layers || (document.getElementById && !document.all)) {

            if (e.which == 2 || e.which == 3) {

                return false;

            }

        }

    }
    if (document.layers) {

        document.captureEvents(Event.MOUSEDOWN);

        document.onmousedown = disableNS;

    } else {

        document.onmouseup = disableNS;

        document.oncontextmenu = disableIE;

    }

    document.oncontextmenu = new Function("return false");

    // console.log(prod[0].ext, 'iaiiii')

    return (

        <div className={fichaTecIndSt.containerFichTec} >

            <NavBarSec />
            <NavBarHover />

            <h2>{String(prod[0].title).toUpperCase()}</h2>

            <div className={fichaTecIndSt.bodyFichTec} >

                <div className={fichaTecIndSt.contentImgFamily} >

                    <div className={fichaTecIndSt.boxImgFich} >

                        <div className={fichaTecIndSt.contListName} >

                            <ul>

                                {

                                    (prod[0].ext != undefined && prod[0].ext.length > 0) &&

                                    <li onClick={e => activeBox(fichaTecIndSt.contentImgExt, 0)} ><p>EXTERIOR</p></li>

                                }

                                {

                                    (prod[0].int != undefined && prod[0].int.length > 0) &&

                                    <li onClick={e => activeBox(fichaTecIndSt.contentImgInt, 1)} ><p>INTERIOR</p></li>

                                }

                                {

                                    (prod[0].pla != undefined && prod[0].pla.length > 0) &&

                                    <li onClick={e => activeBox(fichaTecIndSt.contentImgPla)} ><p>PLANOS</p></li>

                                }

                            </ul>

                        </div>

                        <div className={fichaTecIndSt.contentGroup} >

                            <div className={fichaTecIndSt.contentImgExt} >

                                {

                                    (prod[0].ext != undefined && prod[0].ext.length > 1) &&

                                    <div className={fichaTecIndSt.contArrowL} onClick={e => carrousellEffect(1, 0)} >

                                        <div className={fichaTecIndSt.arrowLeft} >

                                            <ChangeHistoryIcon sx={{ color: '#B90000', fontSize: 50 }} />

                                        </div>

                                    </div>

                                }

                                {

                                    (prod[0].ext != undefined && prod[0].ext.length > 0) &&

                                    <div className={fichaTecIndSt.imgCarrousell} >

                                        {

                                            prod[0].ext.map(e => <img src={e} />)

                                        }

                                    </div>

                                }

                                {

                                    (prod[0].ext != undefined && prod[0].ext.length > 1) &&

                                    <div className={fichaTecIndSt.contArrowR} onClick={e => carrousellEffect(0, 0)} >

                                        <div className={fichaTecIndSt.arrowRigth} >

                                            <ChangeHistoryIcon sx={{ color: '#B90000', fontSize: 50 }} />

                                        </div>

                                    </div>

                                }

                            </div>

                            <div className={fichaTecIndSt.contentImgInt} >

                                {

                                    (prod[0].int != undefined && prod[0].int.length > 1) &&

                                    <div className={fichaTecIndSt.contArrowL} onClick={e => carrousellEffect(1, 1)} >

                                        <div className={fichaTecIndSt.arrowLeft} >

                                            <ChangeHistoryIcon sx={{ color: '#B90000', fontSize: 50 }} />

                                        </div>

                                    </div>

                                }

                                {

                                    (prod[0].int != undefined && prod[0].int.length > 0) &&

                                    <div className={fichaTecIndSt.imgCarrousell} >

                                        {

                                            prod[0].int.map(e => <img src={e} />)

                                        }

                                    </div>

                                }

                                {

                                    (prod[0].int != undefined && prod[0].int.length > 1) &&

                                    <div className={fichaTecIndSt.contArrowR} onClick={e => carrousellEffect(0, 1)} >

                                        <div className={fichaTecIndSt.arrowRigth} >

                                            <ChangeHistoryIcon sx={{ color: '#B90000', fontSize: 50 }} />

                                        </div>

                                    </div>

                                }

                            </div>

                            <div className={fichaTecIndSt.contentImgPla} >

                                {

                                    (prod[0].pla != undefined && prod[0].pla.length > 0) &&

                                    <div className={fichaTecIndSt.imgCarrousell} >

                                        <img src={prod[0].pla[0]} />

                                    </div>

                                }

                            </div>

                        </div>

                    </div>

                </div>

                <div className={fichaTecIndSt.contentPrice} >

                    <div className={fichaTecIndSt.contDescProd} >

                        <h3>DESCRIPCIÓN</h3>

                        {

                            (prod[0]?.tipo === "casaContainer" && prod[0]?.lista === "CLASSIC") ?

                                <p>

                                    Si estás buscando una casa económica y de rápida realización,
                                    nuestra casa classic de {prod[0].sup}m² es la opción ideal para vos, es amplia,
                                    luminosa y cuenta con la capacidad perfecta para habitar con todas las comodidades.
                                    Posee aislación térmica y acústica de paredes,
                                    cuenta con instalación eléctrica y de agua;
                                    viene equipada con el mobiliario necesario para  cocina y baños.
                                    Además, es completamente personalizable.

                                </p> : (prod[0]?.tipo === "casaContainer" && prod[0]?.lista === "COMFORT") ?

                                    <p>

                                        Si estás buscando una casa sustentable, con terminaciones de calidad y de rápida realización,
                                        nuestra casa comfort de {prod[0].sup}m² es la opción ideal para vos, es amplia,
                                        luminosa y cuenta con la capacidad perfecta para habitar con todas las comodidades.
                                        Posee aislación térmica y acústica de paredes y cuenta con instalación completa
                                        de agua y electricidad. Además, viene equipada con el mobiliario necesario para cocina y baños,
                                        y es completamente personalizable.

                                    </p> : (prod[0]?.tipo === "casaContainer" && prod[0]?.lista === "PREMIUM") ?

                                        <p>

                                            Si estás buscando una casa exclusiva, moderna, con terminaciones de primera calidad y de rápida realización,
                                            nuestra casa {String(prod[0].title).split(' ')[0]} premium  de {prod[0].sup}m² es la opción ideal para vos, es amplia, luminosa y cuenta con la capacidad
                                            perfecta para habitar con todas las comodidades que vos y tu familia necesitan.
                                            Posee aislación térmica y acústica de paredes, revestimientos e instalación eléctrica y de agua completa.
                                            Además, viene equipada con el mobiliario necesario para la cocina y baño,  y es completamente personalizable.

                                        </p> : prod[0]?.tipo === "casaCampo" ?

                                            <p>

                                                Si estás buscando una casa ideal para descansar y disfrutar de momentos inolvidables en familia,
                                                nuestra casa de campo con pileta es la opción ideal para vos. Es amplia,
                                                luminosa y cuenta con la capacidad perfecta para habitar con todas las comodidades que vos y tu familia necesitan.
                                                Posee aislación térmica y acústica de paredes e instalación eléctrica y de agua completa.
                                                Además, es completamente personalizable.

                                            </p> : prod[0]?.tipo === "oficina" ?

                                                <p>

                                                    Si buscas un espacio ideal para tu empresa o emprendimiento,
                                                    nuestra oficina de {prod[0].sup}m² es la opción ideal para vos, es amplia,
                                                    luminosa y cuenta con las comodidades que vos y tu equipo de trabajo necesitan.
                                                    Posee aislación térmica y acústica de paredes, e instalación eléctrica y de agua completa.
                                                    Viene equipada con el mobiliario necesario para la cocina y baño,
                                                    y es completamente personalizable, vos elegís cada detalle.

                                                </p> : prod[0]?.tipo === "emprendimiento" ?

                                                    <p>

                                                        Si buscas un espacio ideal para empezar con tu restaurante, nuestro foodtruck  de {prod[0].sup}m²  es la opción ideal para vos,
                                                        es transportable, amplio, luminoso y cuenta con las comodidades que vos y tu equipo de trabajo necesitan.
                                                        Posee aislación térmica y acústica de paredes, e instalación eléctrica y de agua completa.
                                                        Además, viene equipado con el mobiliario necesario para los distintos ambientes y tenés la opción de personalizar
                                                        cada detalle de tu negocio modular.

                                                    </p> : prod[0]?.tipo === "combo" ?

                                                        <p>

                                                            Si tenés un terreno amplio y querés construir más que una casa, nuestros combos containers son la opción más práctica
                                                            y económica para vos. Accedé a nuestra financiación y pagá en cómodas cuotas.

                                                        </p> : prod[0]?.tipo === "tiny" ?

                                                            <p>

                                                                Si te gusta la idea de vivir en una casa minimalista, ecológica, y transportable, nuestra tiny house de {prod[0].sup}m²
                                                                es la opción ideal para vos. Su arquitectura está diseñada para ser una casa cómoda y completamente funcional.
                                                                Posee aislación térmica y acústica de paredes e instalación eléctrica y de agua completa.
                                                                Y es  personalizable, podés diseñar los ambientes de tu tiny como vos quieras.

                                                            </p> : prod[0]?.tipo === "exteriores" && prod[0]?.tipoProd === "garage" ?

                                                                <p>

                                                                    Si buscas construir un espacio resistente donde resguardar tu auto,
                                                                    nuestro garage container de 30m² es la mejor solución para vos.
                                                                    Su sistema constructivo es práctico, trasladable y de rápida realización.
                                                                    Posee aislación térmica y acústica de paredes e instalación eléctrica.
                                                                    Además, es completamente personalizable, vos elegís cada detalle.

                                                                </p> : prod[0]?.tipo === "exteriores" && prod[0]?.tipoProd === "jardinInvierno" ?

                                                                    <p>

                                                                        Si te encantan las plantas y querés tener tu propio invernadero,
                                                                        nuestro jardín de invierno comfort de 30m² es la opción perfecta para vos.
                                                                        Es de rápida fabricación, 100% ecológico y completamente personalizable, vos elegís cada detalle.

                                                                    </p> : prod[0]?.tipo === "exteriores" && prod[0]?.tipoProd === "pileta" ?

                                                                        <p>

                                                                            Si querés una piscina de rápida realización para disfrutar de momentos inolvidables en familia y amigos,
                                                                            nuestra pileta de  {prod[0].sup}m² es la mejor opción para vos. Es sustentable, y fácil de transportar.
                                                                            Podés ubicarla en el lugar que quieras y pagarla 100% financiada y en cómodas cuotas.

                                                                        </p> : prod[0]?.tipo === "exteriores" && prod[0]?.tipoProd === "quincho" ?

                                                                            <p>

                                                                                Si buscas instalar en tu terreno un quincho moderno, transportable y con acabados de calidad,
                                                                                nuestro quincho de  {prod[0].sup}m²  es la opción perfecta para vos.  Posee aislación térmica y acústica de paredes,
                                                                                e instalación eléctrica y de agua completa. Viene equipado con el mobiliario necesario para los distintos ambientes
                                                                                y  lo mejor  es que es completamente personalizable, vos elegís cada detalle.

                                                                            </p> : prod[0]?.tipo === "duplex" &&

                                                                            <p>

                                                                                Si estás buscando una casa amplia, luminosa, y de rápida realización, nuestro dúplex container de {prod[0].sup}m² es perfecto para vos
                                                                                y tu familia. Posee aislación térmica y acústica de paredes, e instalación eléctrica y de agua completa.
                                                                                Además, viene equipado con el mobiliario necesario para la cocina y baño,  y es completamente personalizable,
                                                                                vos elegís cada detalle de tu próximo hogar.

                                                                            </p>

                        }

                    </div>

                    <div className={fichaTecIndSt.priceSuscrip} >

                        <h3>ADHESIÓN</h3>

                        {

                            prod[0].suscripStatus ?

                                <div>

                                    <p className={fichaTecIndSt.txtTachado} >Precio: <b>{prod[0].priceSus}</b></p>

                                    <p>Precio Promocional: <b>{prod[0].priceSusDesc}</b></p>

                                </div>

                                :

                                <div>

                                    <p>Precio: <b>{prod[0].priceSus}</b></p>

                                </div>

                        }

                    </div>

                    <div className={fichaTecIndSt.contentCuot} >

                        <h3>CANTIDAD DE CUOTAS:</h3>

                        <div className={fichaTecIndSt.cantCuot} >

                            <div>

                                <RadioGroup name="use-radio-group" defaultValue="first" >

                                    <MyFormControlLabel value="first" label="120 Cuotas" control={

                                        <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                                    } />

                                    <MyFormControlLabel value="second" label="240 Cuotas" control={

                                        <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                                    } />

                                </RadioGroup>

                            </div>

                            <p  >${!estRadio ? prod[0].preCuot120 : prod[0].preCuot240}</p>

                        </div>

                        <a href='https://wa.link/i1y028' >

                            <div className={fichaTecIndSt.btnFinan} >

                                <h3>FINANCIALA HOY</h3>

                            </div>

                        </a>

                    </div>

                </div>

            </div>

            <div className={fichaTecIndSt.contFichTec} >

                <h3>Ficha Técnica</h3>

                <div className={fichaTecIndSt.contFichText} >

                    <div className={fichaTecIndSt.contTextList} >

                        {

                            prod[0].tipoProd === "pileta" ?


                                <ul>

                                    {prod[0].txtResumen != undefined && <li onClick={e => textEffect(0)} ><p>Resumen</p></li>}

                                    {prod[0].txtMat != undefined && <li onClick={e => textEffect(1)} ><p>Materiales</p></li>}

                                    {prod[0].txtComp != undefined && <li onClick={e => textEffect(2)} ><p>Componentes</p></li>}

                                    {prod[0].txtInc != undefined && <li onClick={e => textEffect(3)} ><p>Incluidos</p></li>}

                                </ul>

                                : prod[0]?.tipoProd === "quincho" ?

                                    <ul>

                                        {prod[0].txtResumen != undefined && <li onClick={e => textEffect(0)} ><p>Resumen</p></li>}

                                        {prod[0].txtBano != undefined && <li onClick={e => textEffect(1)} ><p>Baño</p></li>}

                                        {prod[0].txtCocina != undefined && <li onClick={e => textEffect(2)} ><p>Cocina</p></li>}

                                        {prod[0].txtAbert != undefined && <li onClick={e => textEffect(3)} ><p>Aberturas</p></li>}

                                        {prod[0].txtRevesti != undefined && <li onClick={e => textEffect(4)} ><p>Revestimiento</p></li>}

                                        {prod[0].txtInstElect != undefined && <li onClick={e => textEffect(5)} ><p>Instalacion Electrica</p></li>}

                                        {prod[0].txtInstAgua != undefined && <li onClick={e => textEffect(6)} ><p>Instalacion Agua</p></li>}

                                        {prod[0].txtPisos != undefined && <li onClick={e => textEffect(7)} ><p>Pisos</p></li>}

                                        {prod[0].txtLavado != undefined && <li onClick={e => textEffect(8)} ><p>Lavado</p></li>}

                                    </ul>

                                    : prod[0]?.tipoProd === "foodtruck" ?

                                        <ul>

                                            {prod[0].txtResumen != undefined && <li onClick={e => textEffect(0)} ><p>Resumen</p></li>}

                                            {prod[0].txtBano != undefined && <li onClick={e => textEffect(1)} ><p>Baño</p></li>}

                                            {prod[0].txtCocina != undefined && <li onClick={e => textEffect(2)} ><p>Cocina</p></li>}

                                            {prod[0].txtAbert != undefined && <li onClick={e => textEffect(prod[0].txtBano != undefined ? 2 : 1)} ><p>Aberturas</p></li>}

                                            {prod[0].txtRevesti != undefined && <li onClick={e => textEffect(prod[0].txtBano != undefined ? 3 : 2)} ><p>Revestimiento</p></li>}

                                            {prod[0].txtEquip != undefined && <li onClick={e => textEffect(prod[0].txtBano != undefined ? 4 : 3)} ><p>Equipamiento</p></li>}

                                            {prod[0].txtInstElect != undefined && <li onClick={e => textEffect(prod[0].txtBano != undefined ? 5 : 4)} ><p>Instalacion Electrica</p></li>}

                                            {prod[0].txtInstAgua != undefined && <li onClick={e => textEffect(prod[0].txtBano != undefined ? 6 : 5)} ><p>Instalacion Agua</p></li>}

                                            {prod[0].txtPisos != undefined && <li onClick={e => textEffect(prod[0].txtBano != undefined ? 7 : 6)} ><p>Pisos</p></li>}

                                        </ul>

                                        : prod[0]?.tipoProd === "casaCampo" ?

                                            <ul>

                                                {prod[0].txtResumen != undefined && <li onClick={e => textEffect(0)} ><p>Resumen</p></li>}

                                                {prod[0].txtBano != undefined && <li onClick={e => textEffect(1)} ><p>Baño</p></li>}

                                                {prod[0].txtBano != undefined && <li onClick={e => textEffect(2)} ><p>Cocina</p></li>}

                                                {prod[0].txtInc != undefined && <li onClick={e => textEffect(3)} ><p>Incluidos</p></li>}

                                                {prod[0].txtAbert != undefined && <li onClick={e => textEffect(4)} ><p>Aberturas</p></li>}

                                                {prod[0].txtRevesti != undefined && <li onClick={e => textEffect(5)} ><p>Revestimiento</p></li>}

                                                {prod[0].txtEquip != undefined && <li onClick={e => textEffect(6)} ><p>Equipamiento</p></li>}

                                                {prod[0].txtInstElect != undefined && <li onClick={e => textEffect(7)} ><p>Instalacion Electrica</p></li>}

                                                {prod[0].txtInstAgua != undefined && <li onClick={e => textEffect(8)} ><p>Instalacion Agua</p></li>}

                                                {prod[0].txtPisos != undefined && <li onClick={e => textEffect(9)} ><p>Pisos</p></li>}

                                                {prod[0].txtPiletaDescrip != undefined && <li onClick={e => textEffect(10)} ><p>Descripcion Pileta</p></li>}

                                                {prod[0].txtPiletaMat != undefined && <li onClick={e => textEffect(11)} ><p>Materiales</p></li>}

                                                {prod[0].txtPiletaComp != undefined && <li onClick={e => textEffect(12)} ><p>Componentes</p></li>}

                                            </ul>

                                            : prod[0]?.tipoProd === "garage" ?

                                                <ul>

                                                    {prod[0].txtAbert != undefined && <li onClick={e => textEffect(0)} ><p>Aberturas</p></li>}

                                                    {prod[0].txtRevesti != undefined && <li onClick={e => textEffect(1)} ><p>Revestimiento</p></li>}

                                                    {prod[0].txtInstElect != undefined && <li onClick={e => textEffect(2)} ><p>Instalacion Electrica</p></li>}

                                                    {prod[0].txtPisos != undefined && <li onClick={e => textEffect(3)} ><p>Pisos</p></li>}

                                                </ul>

                                                : prod[0]?.tipoProd === "jardinInvierno" ?

                                                    <ul>

                                                        {prod[0].txtLavado != undefined && <li onClick={e => textEffect(0)} ><p>Lavado</p></li>}

                                                        {prod[0].txtAbert != undefined && <li onClick={e => textEffect(1)} ><p>Aberturas</p></li>}

                                                        {prod[0].txtRevesti != undefined && <li onClick={e => textEffect(2)} ><p>Revestimiento</p></li>}

                                                        {prod[0].txtInstElect != undefined && <li onClick={e => textEffect(3)} ><p>Instalacion Electrica</p></li>}

                                                        {prod[0].txtInstAgua != undefined && <li onClick={e => textEffect(4)} ><p>Instalacion Agua</p></li>}

                                                        {prod[0].txtPisos != undefined && <li onClick={e => textEffect(5)} ><p>Pisos</p></li>}

                                                    </ul>

                                                    :

                                                    <ul>

                                                        {prod[0].txtResumen != undefined && <li onClick={e => textEffect(0)} ><p>Resumen</p></li>}

                                                        {prod[0].txtBano != undefined && <li onClick={e => textEffect(1)} ><p>Baño</p></li>}

                                                        {prod[0].txtCocina != undefined && <li onClick={e => textEffect(2)} ><p>Cocina</p></li>}

                                                        {prod[0].txtAbert != undefined && <li onClick={e => textEffect(3)} ><p>Aberturas</p></li>}

                                                        {prod[0].txtRevesti != undefined && <li onClick={e => textEffect(4)} ><p>Revestimiento</p></li>}

                                                        {prod[0].txtEquip != undefined && <li onClick={e => textEffect(5)} ><p>Equipamiento</p></li>}

                                                        {prod[0].txtInstElect != undefined && <li onClick={e => textEffect(6)} ><p>Instalacion Electrica</p></li>}

                                                        {prod[0].txtInstAgua != undefined && <li onClick={e => textEffect(7)} ><p>Instalacion Agua</p></li>}

                                                        {prod[0].txtPisos != undefined && <li onClick={e => textEffect(8)} ><p>Pisos</p></li>}

                                                        {prod[0].txtLavado != undefined && <li onClick={e => textEffect(9)} ><p>Lavado</p></li>}

                                                    </ul>

                        }

                    </div>

                    <div className={fichaTecIndSt.contDescFich} >

                        {

                            prod[0].txtResumen != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtResumen.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtBano != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtBano.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtCocina != undefined &&


                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtCocina.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtMat != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtMat.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtComp != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtComp.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtInc != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtInc.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtLavado != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtLavado.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtAbert != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtAbert.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtRevesti != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtRevesti.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtEquip != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtEquip.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtInstElect != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtInstElect.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtInstAgua != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtInstAgua.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtPisos != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtPisos.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtPiletaDescrip != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtPiletaDescrip.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtPiletaMat != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtPiletaMat.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                        {

                            prod[0].txtPiletaComp != undefined &&

                            <div className={fichaTecIndSt.txtProduct} >

                                <ul>

                                    {

                                        prod[0].txtPiletaComp.map(e => <li>{e}</li>)

                                    }

                                </ul>

                            </div>

                        }

                    </div>

                </div>

            </div>

            {

                (prod[0].linkVideo !== undefined && String(prod[0].linkVideo).length > 0) &&

                <div className={fichaTecIndSt.contentVideo} >

                    <video

                        src={prod[0].linkVideo}
                        autoplay
                        controls

                    ></video>

                </div>

            }

            <div className={fichaTecIndSt.containerBtn} >

                <div className={fichaTecIndSt.contBtnPago} >

                    <h3>¡EMPEZÁ A CONSTRUIR HOY!</h3>

                    <a href='https://wa.link/i1y028' >

                        {/* <Link to={{
        pathname: `/PagoProduct/${prod[0].id}`,
        state: { prod }
    }} > */}

                        {/* <div className={fichaTecIndSt.btnLinkPago} >ACCEDÉ A NUESTRA FINANCIACIÓN</div> */}

                        <div className={fichaTecIndSt.btnLinkPago} >CONTACTAR CON VENTAS</div>

                        {/* </Link> */}

                    </a>

                </div>

            </div>

            <Footer />

        </div>

    )

}