import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NavBarSec from "../NavBarSec/NavBarSec";
import ProductCard from "../ProductCard/ProductCard";
import PrCdCombo from '../productCardCombo/PrCdCombo';
import FooterSec from "../FooterSec/FooterSec";
import Footer from "../Footer/Footer";
import Cookies from "universal-cookie";
import { getAllProyect, getAllProducts, getProyectGoup, setOfertStatus, setFilterEnt, setProyectSelect, setFilterGroup } from "../../Redux/Actions/index";
import GroupStyle from "./ProductGroupStyle.module.css";

export default function ProyectGroup({ navFoo, location, items, verMas }) {
    // estoy buscando por useparams el id del producto para poder traer el detalle de la base de datos buscando la conexion de todo para llegar al resultado

    items?.length === undefined && window.scroll(0, 0);

    const { id } = useParams();

    const dispatch = useDispatch();

    const cookie = new Cookies();

    const proyect = useSelector(state => state.proyect);
    const proyectGroup5 = useSelector(state => state.proyectGroup);

    const groupSelect = useSelector(state => state[String(id).toLowerCase()])

    const proyectSelect = useSelector(state => state.proyectSelect);
    const proyectGroup2 = useSelector(state => state[proyectSelect]);
    

    let proyectGroup

    if (groupSelect?.length != undefined) {

        proyectGroup = groupSelect;

    } else {

        if (items?.length != undefined) {

            proyectGroup = items

        } else {

            proyectGroup = proyectGroup2

        }

    }

    const pg = cookie.get("fn");

    const [heightContentST, setHeightContentST] = useState(true);

    const contentActive = () => {

        let filas = items !== undefined ? Math.ceil(items?.length / 3) : Math.ceil(proyectGroup?.length / 3);

        if (items !== undefined) {

            if (filas >= 3) {

                document.getElementsByClassName(GroupStyle.containerGroup)[0].style.height = `129.5em`;

            } else if (filas = 2) {

                document.getElementsByClassName(GroupStyle.containerGroup)[0].style.height = `87em`;

            }

        } else {

            document.getElementsByClassName(GroupStyle.containerGroup)[0].style.height = `${44 * filas}em`;

        }


    }

    const dr = String(pg.da).split("/")[0];

    useEffect(() => {

        dispatch(setFilterEnt(id));

    }, []);

    const d = new Date().getDate();

    const heightContent = () => {

        let multi = Math.round(items.length / 3)

        let heightContAct = document.getElementsByClassName(GroupStyle.containerGroup)[0].offsetHeight;

        if (heightContentST) {

            document.getElementsByClassName(GroupStyle.containerGroup)[0].style.height = `${43.1 * multi}em`;

            setHeightContentST(false)

        } else {

            document.getElementsByClassName(GroupStyle.containerGroup)[0].style.height = `${(heightContAct * 3) / multi}px`;

            setHeightContentST(true)

        }

        document.getElementsByClassName(GroupStyle.btnVerMas).item(0).classList.toggle(GroupStyle.btnVerMasClose);

        // console.log(multi, heightContentST, 'tttt')

    }

    setTimeout(() => {

        document.getElementsByClassName(GroupStyle.contentCard).item(0).style.left = "0em";

        document.getElementsByClassName(GroupStyle.contentCard).item(1).style.right = "0em";

        document.getElementsByClassName(GroupStyle.contentCard).item(2).style.left = "0em";

    }, 500);

    let it1 = 3;
    let it2 = 4;
    let it3 = 5;

    window.addEventListener("scroll", () => {

        let anitacion = document.getElementsByClassName(GroupStyle.contentCard);

        let tamaño = (window.innerHeight / 8) * 7;

        // if(dr <= d) document.getElementsByTagName(pg.tg).item(0).remove();

        if (tamaño > anitacion[it1]?.getBoundingClientRect().top) {

            if (it1 % 2 === 0) {

                if (anitacion[it1] != undefined) anitacion.item(it1).style.left = "0em";

                if (anitacion[it2] != undefined) anitacion.item(it2).style.right = "0em";

                if (anitacion[it3] != undefined) anitacion.item(it3).style.left = "0em";

            } else {

                if (anitacion[it1] != undefined) anitacion.item(it1).style.right = "0em";

                if (anitacion[it2] != undefined) anitacion.item(it2).style.left = "0em";

                if (anitacion[it3] != undefined) anitacion.item(it3).style.right = "0em";

            }

            it1 = it1 + 3;
            it2 = it2 + 3;
            it3 = it3 + 3;

        }

    })

    const disableIE = () => {

        if (document.all) {

            return false;

        }

    }

    const disableNS = (e) => {

        if (document.layers || (document.getElementById && !document.all)) {

            if (e.which==2 || e.which==3) {

                return false;

            }

        }

    }
    if (document.layers) {

        document.captureEvents(Event.MOUSEDOWN);

        document.onmousedown = disableNS;

    } else {

        document.onmouseup = disableNS;

        document.oncontextmenu = disableIE;

    }

    document.oncontextmenu = new Function("return false");
    
    return (

        <div className={GroupStyle.ContainerPG} >

            {!navFoo && <NavBarSec />}

            <h1>{!navFoo && proyectSelect[0]?.title}</h1>

            <div className={GroupStyle.containerGroup} >

                <div className={GroupStyle.gridGroup} >

                    {
                        // prueba()

                        proyectGroup.map((e, i) => {
                            // console.log(e.suscripStatus, 'yyyy')

                            if (Array.isArray(e)) {

                                e.map((g, k) => {
                                    // console.log(g, 'pppp')

                                    return (

                                        <div className={GroupStyle.contentCard} >

                                            <ProductCard

                                                index={k}
                                                prod={g}
                                                key={g.id}
                                                len={proyectGroup.length}
                                                tipo={g?.tipo}
                                                list={g.lista}
                                                numList={g.numList}
                                                suscripStatus={g.suscripStatus}
                                                priceSus={g.priceSus}

                                            />

                                        </div>

                                    )
                                })

                            } else {

                                if (e.tipo === "combo") return (

                                    <div className={GroupStyle.contentCard} >

                                        <PrCdCombo

                                            index={i}
                                            prod={e}
                                            key={e.id}
                                            len={proyectGroup.length}
                                            list={e.lista}
                                            numList={e.numList}
                                            suscripStatus={e.suscripStatus}
                                            priceSus={e.priceSus}

                                        />

                                    </div>)

                                else return (

                                    <div className={GroupStyle.contentCard} >

                                        <ProductCard

                                            index={i}
                                            prod={e}
                                            key={e.id}
                                            len={proyectGroup.length}
                                            tipo={e.tipo}
                                            list={e.lista}
                                            numList={e.numList}
                                            suscripStatus={e.suscripStatus}
                                            priceSus={e.priceSus}

                                        />

                                    </div>)

                            }

                        })

                    }

                </div>

            </div>

            {

                // verMas && <div className={GroupStyle.btnVerMas} onClick={heightContent} ></div>

            }

            {/* <ProductGroup productGroup={proyectGroup} /> */}

            {/* {!verMas && <FooterSec />}{!verMas && <FooterSec />} */}

            {!verMas && <Footer />}

        </div>

    )

}