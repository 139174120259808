import React from "react";
import HeadBandList3St from "./HeadBandList3St.module.css";

export default function HeadBandList3({ list }) {

    return (

        <div className={HeadBandList3St.headBand} ><h3>{String(list).toUpperCase()}</h3></div>

    )

}