import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TransformIcon from '@mui/icons-material/Transform';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import ShowerIcon from '@mui/icons-material/Shower';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import BannerFinalSecond from '../Banner/BannerFinalSecond';
import BannerSlideSecond from '../Banner/BannerSlideSecond';
import prCdComboSt from "./PrCdComboSt.module.css";

export default function PrCdCombo({ prod, index, len }) {

    const arr = [

        "https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/ImgPublicaciones%2F60m2.JPG?alt=media&token=9b62065d-a866-456f-8de5-4392b1b8d37e",
        "https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Planos%2F75m2.jpg?alt=media&token=33b7c3ff-6ed1-4664-b366-99689c49aa4b",
        "https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/ImgPublicaciones%2F60m2.JPG?alt=media&token=9b62065d-a866-456f-8de5-4392b1b8d37e",
        "https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Planos%2F75m2.jpg?alt=media&token=33b7c3ff-6ed1-4664-b366-99689c49aa4b"
    ];

    const [estRadio, setEstRadio] = useState(false);

    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
        ({ theme, checked }) => ({
            '.MuiFormControlLabel-label': checked && {
                color: "#B90000",
            },
        }),
    );

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
            setEstRadio(radioGroup.value === props.value);

        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    MyFormControlLabel.propTypes = {
        /**
         * The value of the component.
         */
        value: PropTypes.any,
    };

    const openPop = () => {

        document.getElementsByClassName(prCdComboSt.contentPopup)[index]?.classList.toggle(prCdComboSt.maxWidth);

    }

    const desDesliz = (i) => {
        // console.log(document.getElementById("popUp").classList,'sssss')
        // console.log(getElementsByClassName(PrCdStyle.detProd).item,'sssss')
        let res = document.getElementsByClassName(prCdComboSt.contentDet).length;
        // console.log(res, 'ppp')
        // -> (((total de div en dom / cantidad total de cards) * posicion de card) + index del div)
        let indEnc = Math.ceil((((res / len) * index) + i));
        // console.log(indEnc, len, index, 'iiii')
        const prodDiv = document.getElementsByClassName(prCdComboSt.detProd)[indEnc];

        // prodDiv.style.display === 'none' ? prodDiv.style.display = 'block' : prodDiv.style.display = 'none';

        document.getElementsByClassName(prCdComboSt.contentDet)[indEnc]?.classList.toggle(prCdComboSt.detList);

        // giro de flecha 

        document.getElementsByClassName(prCdComboSt.iconArrow)[indEnc]?.classList.toggle(prCdComboSt.iconGiro);

    }

    let i = 1;

    const next = (e) => {

        const contentSl = document.getElementsByClassName(prCdComboSt.imgSlider);

        const fistElement = contentSl.item(1).children[0];

        const widthImage = contentSl.item(1).children[0].offsetWidth;

        contentSl.item(1).style.transform = (`translateX(-${(widthImage * e)}px)`);

        if (e === contentSl.item(1).children.length) {

            const positionFirst = () => {

                contentSl.item(1).style.trasitions = 'none';

                contentSl.item(1).style.transform = 'translateX(0)';

                contentSl.item(1).addEventListener("transitionend", positionFirst);

            }

            contentSl.item(1).addEventListener("transitionend", positionFirst);

        }

    }

    return (

        <div className={prCdComboSt.cardProductContent} >

            <div className={prCdComboSt.headband} ><h3>{prod.lista?.toUpperCase()}</h3></div>

            {/* <img src={prod?.file} alt={prod?.title} /> */}
            <div className={prCdComboSt.OfertaContImg}>

                <img src={prod?.file} alt={prod?.title} />

            </div>

            <div className={prCdComboSt.OfertaCarac} >

                <div>SUP:<TransformIcon />{prod.sup}M²</div>

                <div>AMB:<NightShelterIcon />{prod.amb}</div>

                <div>BAÑO:<ShowerIcon />{prod.baño}</div>

            </div>

            <h3 className={prCdComboSt.OfertaCardSubtitle}>{String(prod?.title).toUpperCase()}</h3>

            <h3 className={prCdComboSt.OfertaCardSubtitle}>¡ENTREGA PROGRAMADA!</h3>

            <div className={prCdComboSt.contentCuot} >

                <h4>CANTIDAD DE CUOTAS</h4>

                <RadioGroup name="use-radio-group" defaultValue="first">

                    <MyFormControlLabel value="first" label="120 Cuotas" control={

                        <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                    } />

                    <MyFormControlLabel value="second" label="240 Cuotas" control={

                        <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                    } />

                </RadioGroup>


            </div>

            <div className={prCdComboSt.contentPrecios} >

                <p className={prCdComboSt.preText} >Cuotas de</p>

                <p className={prCdComboSt.preNum} >${!estRadio ? prod.preCuot120 : prod.preCuot240}</p>

                <p className={prCdComboSt.preText} >Precio de lista</p>

                <p className={prCdComboSt.preNum} >${prod.price}</p>

                <p className={prCdComboSt.preText} >Precio promocion (contado)</p>

                <p className={prCdComboSt.preNum} >${prod.priceDesc}</p>

            </div>

            {/* <p>{e?.description}</p>  */}

            <div className={prCdComboSt.contentBtn} >

                <div className={prCdComboSt.btnVer} onClick={openPop} >VER MÁS</div> {/* popUp probar con esta funcion - openPop */}

                <div className={prCdComboSt.btnCon} ><Link to="/contactoTest"><p>CONSULTAR</p></Link></div>

            </div>

            <div className={prCdComboSt.contentPopup} >

                <div className={prCdComboSt.closePop} >

                    <div className={prCdComboSt.closePopIcon} ><HighlightOffIcon onClick={openPop} sx={{ color: 'white', fontSize: 30 }} /></div>

                </div>

                <div className={prCdComboSt.containerP} >

                    <div className={prCdComboSt.contentImg} >

                        <div className={prCdComboSt.imgSlider} >

                            {

                                prod.imgProduct.length > 1 ?

                                    <BannerFinalSecond arrLength={arr.length} banner={true} index={index} >

                                        {

                                            // arr.map((e, i) => <BannerSlideSecond img={e} key={i} />)

                                            prod.imgProduct?.map((e, i) => <BannerSlideSecond img={e} key={i} />)


                                        }

                                    </BannerFinalSecond> :

                                    <div className={prCdComboSt.imgEffectOff} ><img src={prod.imgProduct[0]} /></div>

                            }

                        </div>

                    </div>

                    <div id="popUp" >

                        <ul>

                            <li className={prCdComboSt.contentDet}>

                                <div className={prCdComboSt.detHead} onClick={e => desDesliz(0)}  >

                                    <p>RESUMEN</p>

                                    <div className={prCdComboSt.iconArrow} >

                                        <KeyboardDoubleArrowDownIcon sx={{ fontSize: 30 }} />


                                    </div>

                                </div>

                                <div className={prCdComboSt.detProd} >

                                    <ul>

                                        {

                                            prod.prod?.map((e, i) => <li key={i} >{e}</li>)

                                        }

                                    </ul>

                                </div>

                            </li>

                        </ul>

                    </div>

                </div>

            </div>

        </div>

    )

}