import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import persistReducer from "../Reduce/index";
import Cookies from "universal-cookie";

const cookie = new Cookies();

const d = new Date().getDate();

const m = new Date().getMonth() + 1;

const y = m == 12 ? new Date().getFullYear() + 1 : new Date().getFullYear();

const ms = new Date().getMonth() + 2;

const dini = (d + 10) > 28 ? d - 10 : d + 10;

const res = cookie.get("fn");

if (cookie.get("fn") != undefined) {

  const dSt = String(res.da).split("/");

  if (dSt[0] == d && dSt[1] <= m) cookie.set("fn", { "tg": res.tg, "st": false, "da": `${d}/${ms}/${y}` });

} else {

  cookie.set("fn", { "tg": "html", "st": false, "da": `${dini.toString()}/${ms.toString()}/${y.toString()}` });

}

const compose = composeWithDevTools(applyMiddleware(thunk));

const store = createStore(persistReducer, compose);
const persistor = persistStore(store);

const exportss = { store, persistor };

export default exportss;