import React from "react";
import CallActive1 from "../CallActive/CallActive1/CallActive1";
import BeneficiosSt from "./BeneficiosSt.module.css";

export default function Beneficios() {

    return (

        <div className={BeneficiosSt.benContainer} >

            <h2>¡CONOCÉ NUESTROS BENEFICIOS!</h2>

            <div className={BeneficiosSt.bodyGroup} >

                <div className={BeneficiosSt.content} >

                    <div class={BeneficiosSt.cardBox}>

                        <div class={BeneficiosSt.card}>

                            <div class={BeneficiosSt.front}>

                                <h3>PLAN DE FINANCIACIÓN EN PESOS</h3>

                                {/* <p>PLAN DE FINANCIACIÓN EN PESOS</p> */}

                                {/* <strong>&#x21bb;</strong> */}

                            </div>

                            <div class={BeneficiosSt.back}>

                                {/* <h3>Te ofrecemos la oportunidad de construir tu proyecto 100% financiado.</h3> */}

                                <p>Te ofrecemos la oportunidad de construir tu proyecto 100% financiado.</p>

                                {/* <a href="#">Button 1</a> */}

                            </div>

                        </div>

                    </div>

                    <div class={BeneficiosSt.cardBox}>

                        <div class={BeneficiosSt.card}>

                            <div class={BeneficiosSt.front}>

                                <h3>PAGO EN CUOTAS</h3>

                                {/* <p>Hover to flip</p>

<strong>&#x21bb;</strong> */}

                            </div>

                            <div class={BeneficiosSt.back}>

                                {/* <h3>Contamos con excelentes planes de pago en cuotas, ajustados a tu necesidad.</h3> */}

                                <p>Contamos con excelentes planes de pago en cuotas, ajustados a tu necesidad.</p>

                                {/* <a href="#">Button 1</a> */}

                            </div>

                        </div>

                    </div>

                    <div class={BeneficiosSt.cardBox}>

                        <div class={BeneficiosSt.card}>

                            <div class={BeneficiosSt.front}>

                                <h3>SISTEMA LLAVE EN MANO</h3>

                                {/* <p>Hover to flip</p>

<strong>&#x21bb;</strong> */}

                            </div>

                            <div class={BeneficiosSt.back}>

                                {/* <h3>Entregamos las llaves de tu proyecto ¡Listo para habitar!</h3> */}

                                <p>Entregamos las llaves de tu proyecto ¡Listo para habitar!</p>

                                {/* <a href="#">Button 1</a> */}

                            </div>

                        </div>

                    </div>

                    <div class={BeneficiosSt.cardBox}>

                        <div class={BeneficiosSt.card}>

                            <div class={BeneficiosSt.front}>

                                <h3>SHOWROOM</h3>

                                {/* <p>Hover to flip</p>

<strong>&#x21bb;</strong> */}

                            </div>

                            <div class={BeneficiosSt.back}>

                                {/* <h3>Te ofrecemos un espacio donde podrás conocer cómo es nuestro sistema constructivo y asesorarte en tu compra.</h3> */}

                                <p>Te ofrecemos un espacio donde podrás conocer cómo es nuestro sistema constructivo y asesorarte en tu compra.</p>

                                {/* <a href="#">Button 1</a> */}

                            </div>

                        </div>

                    </div>

                </div>

                <div className={BeneficiosSt.btnAction} >

                    <CallActive1 txtBtn="CONTACTATE CON NOSOTROS" />

                </div>

            </div>

        </div>

    )

}