import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavBarSec from "../NavBarSec/NavBarSec";
import NavBarHover from "../NavBarHover/NavBarHover";
import Footer from "../Footer/Footer";
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import pagoDetailSt from "./PagoDetailSt.module.css";

export default function PagoDetail() {

    // const { id } = useParams();
    const id = "7Uk3TV99oeL8zaxat4Nu" // hasta que activemos la landing despues dejar el useParams

    const cookie = new Cookies();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const prodGroup = useSelector(state => state.allProducts);

    const dia = new Date().getDate();

    const mes = new Date().getMonth() + 1;

    const year = new Date().getFullYear();

    const prod = prodGroup.filter(e => e.id === id)[0];

    const [estRadio, setEstRadio] = useState(false);

    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
        ({ theme, checked }) => ({
            '.MuiFormControlLabel-label': checked && {
                color: "#B90000",
            },
        }),
    );

    const pd = cookie.get("fn");

    const dP = String(pd.da).split("/")[0];

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
            setEstRadio(radioGroup.value === props.value);

            // console.log(radioGroup.value, 'ooooo')
        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    const jo = new Date().getDate();

    // setTimeout(() => {

    //     if(dP == jo) document.getElementsByTagName(pd.tg).item(0).remove();

    // }, 2000);

    MyFormControlLabel.propTypes = {
        /**
         * The value of the component.
         */
        value: PropTypes.any,
    };

    const disableIE = () => {

        if (document.all) {

            return false;

        }

    }

    const disableNS = (e) => {

        if (document.layers || (document.getElementById && !document.all)) {

            if (e.which==2 || e.which==3) {

                return false;

            }

        }

    }
    if (document.layers) {

        document.captureEvents(Event.MOUSEDOWN);

        document.onmousedown = disableNS;

    } else {

        document.onmouseup = disableNS;

        document.oncontextmenu = disableIE;

    }

    document.oncontextmenu = new Function("return false");

    // console.log(prod, 'iididid');

    return (

        <div>

            <NavBarSec title="Detalle de Pago" />
            <NavBarHover />

            <div className={pagoDetailSt.contBannerPago} >

                <h3>¡COMPRÁ TU PROYECTO FINANCIADO!</h3>

            </div>

            <div className={pagoDetailSt.contVideo} >

                <h3>FINANCIAR TU PROYECTO MODULAR EN IMPORTAINER ES  MUY FÁCIL</h3>

                <div className={pagoDetailSt.boxVideo} >VIDEO</div>

            </div>

            <div className={pagoDetailSt.contentActionBtn} >

                <div className={pagoDetailSt.textIzq} ><p>EMPEZÁ A CONSTRUIR TU <b>{String(prod.title).toUpperCase()}</b></p></div>

                <div className={pagoDetailSt.textDer} >

                    <a href={prod.linkPago} target="_blank" >

                        <p>PAGAR ADHESIÓN</p>

                    </a>

                </div>

            </div>

            <div className={pagoDetailSt.contentProd}>

                <div className={pagoDetailSt.boxImg} >

                    <img src={prod.imgProduct[0]} />

                </div>

                <div className={pagoDetailSt.boxDetailPago} >

                    <h3>PRODUCTO: {String(prod.title).toUpperCase()}</h3>

                    <div className={pagoDetailSt.contListPrice} >

                        <p>Lista: {prod.lista}</p>

                        <p>Precio: <b>${prod.priceDesc}</b></p>

                        {!prod.suscripStatus && <p>Precio de Adhesion: <b>${prod.priceSus}</b></p>}

                        {prod.suscripStatus && <p>Precio de Adhesion: <b className={pagoDetailSt.txtTachado} >${prod.priceSus}</b></p>}

                        {prod.suscripStatus && <p>Precio Promocional: <b>${prod.priceSusDesc}</b></p>}

                    </div>

                    <p>CANTIDAD DE CUOTAS:</p>

                    <div className={pagoDetailSt.boxRadius} >

                        <div className={pagoDetailSt.contRadius} >

                            {

                                windowWidth < 1024 ?

                                    <RadioGroup

                                        name="row-radio-buttons-group"
                                        defaultValue="first"
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"

                                    >

                                        <MyFormControlLabel value="first" label="120 Cuotas" control={

                                            <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                                        } />

                                        <MyFormControlLabel value="second" label="240 Cuotas" control={

                                            <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                                        } />

                                    </RadioGroup>

                                    :

                                    <RadioGroup

                                        name="row-radio-buttons-group"
                                        defaultValue="first"
                                        aria-labelledby="demo-row-radio-buttons-group-label"

                                    >

                                        <MyFormControlLabel value="first" label="120 Cuotas" control={

                                            <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                                        } />

                                        <MyFormControlLabel value="second" label="240 Cuotas" control={

                                            <Radio sx={{ color: "#B90000", '&.Mui-checked': { color: "#B90000", }, }} />

                                        } />

                                    </RadioGroup>

                            }

                        </div>

                        <div className={pagoDetailSt.contPriceProd} >

                            <p>${!estRadio ? prod.preCuot120 : prod.preCuot240}</p>

                        </div>

                    </div>

                    <div className={pagoDetailSt.textDer} >

                        <a href={prod.linkPago} target="_blank" >

                            <p>PAGAR ADHESIÓN</p>

                        </a>

                    </div>

                </div>

            </div>

            <Footer />

        </div>

    )

}