import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setOfertStatus } from "../../Redux/Actions/index";
import NBHoverSt from "./NavBarHoverSt.module.css";

export default function NavBarHover() {

    const dispatch = useDispatch();

    window.addEventListener("scroll", () => {

        if (window.innerWidth >= 800) {

            if (window.scrollY >= 300) {

                document.getElementsByClassName(NBHoverSt.mnuContent).item(0).style.transition = "opacity 1s, top .6s";
                document.getElementsByClassName(NBHoverSt.mnuContent).item(0).classList.add(NBHoverSt.mnuContentOp);

            } else if (window.scrollY < 300) {

                document.getElementsByClassName(NBHoverSt.mnuContent).item(0).style.transition = "opacity .6s, top 1s";
                document.getElementsByClassName(NBHoverSt.mnuContent).item(0).classList.remove(NBHoverSt.mnuContentOp)

            }

        }

    })

    return (

        <div className={NBHoverSt.mnuContent} >

            <div className={NBHoverSt.logo}>

                <Link to="/">
                    <img src='https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Logos%2FLOGO%201000X1000_Mesa%20de%20trabajo%201.png?alt=media&token=544ff206-b6d6-41cd-b6b6-bcee5f06a347' className={NBHoverSt.logo} alt="logo" />
                </Link>

            </div>

            <ul className={NBHoverSt.navUl}>

                <li><Link to="/" style={{ color: '#000' }} ><p>INICIO</p></Link></li>
                <li><Link to="/proyectosNew" style={{ color: '#000' }} onClick={e => dispatch(setOfertStatus(false))} ><p>PROYECTOS</p></Link></li>
                <li><Link to="/sistemaConstructivo" style={{ color: '#000' }} ><p>SISTEMA CONSTRUCTIVO</p></Link></li>
                <li><Link to="/EntregasCategory" style={{ color: '#000' }} ><p>ENTREGAS</p></Link></li>
                {/* <li><Link to="/" ><p>FRANQUICIAS</p></Link></li>
              <li><Link to="/" ><p>ENTREGAS</p></Link></li>
              <li><Link to="/" ><p>SOBRE NOSOTROS</p></Link></li>
              <li><Link to="/" ><p>SUCURSALES</p></Link></li> */}
                <li><Link to="/contacto" style={{ color: '#000' }} ><p>CONTACTO</p></Link></li>
                {/* <li><LoginUser /></li> */}

            </ul>

        </div>

    )

}