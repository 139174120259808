import React from "react";
import { Link } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CallActive2St from "./CallActive2St.module.css";

export default function CallActive2({txtBtn}) {

    return (

        <div className={CallActive2St.CallActive2Content} >

            <Link to="/contactoTest" ><p>{txtBtn}</p></Link>

            <div className={CallActive2St.boxSend} >

                <SendIcon sx={{color: "rgba(255, 55, 55, 0.8)"}} />

            </div>

        </div>

    )

}