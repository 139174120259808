import React, { useState, useRef, useEffect } from 'react'
import CtaRegresiveSt from "./CtaRegresiveSt.module.css";

export default function CtaRegresive() {

    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    let countDown = new Date('Apr 30, 2023 00:00:00').getTime()

    let now = new Date().getTime();
    let distance = countDown - now;

    let dayRest = Math.floor(distance / (day))
    let hourRest = Math.floor((distance % (day)) / (hour))
    let minRest = Math.floor((distance % (hour)) / (minute))
    let secondRest = Math.floor((distance % (minute)) / second)

    const id = setInterval(function () {

        let now = new Date().getTime();
        let distance = countDown - now;

        document.getElementById('dias').innerText = Math.floor(distance / (day))
        document.getElementById('horas').innerText = Math.floor((distance % (day)) / (hour))
        document.getElementById('minutos').innerText = Math.floor((distance % (hour)) / (minute))
        document.getElementById('segundos').innerText = Math.floor((distance % (minute)) / second);

    }, second)

    if ((dayRest <= 2 && hourRest <= 11 && minRest <= 13 && secondRest <= 15) && document.getElementsByClassName(CtaRegresiveSt.contenedor)?.item(0) != undefined) {

        document.getElementsByClassName(CtaRegresiveSt.contenedor)?.item(0).remove("div");

    }

    if ((dayRest <= 2 && hourRest <= 11 && minRest <= 13 && secondRest <= 15)) {
        
        clearInterval(id);

    }

    return (

        <div class={CtaRegresiveSt.contenedor}>

            <h3>¡Ya casi empiezan nuestras super ofertas!</h3>

            <div class={CtaRegresiveSt.contenido}>
                <div class={CtaRegresiveSt.contador}>
                    <div class={CtaRegresiveSt.responsivo1}>
                        <div class={CtaRegresiveSt.cartel}>
                            <div id="dias"></div>
                            <div class={CtaRegresiveSt.h3}><h3>Días</h3></div>
                        </div>
                        <div class={CtaRegresiveSt.cartel}>
                            <div id="horas"></div>
                            <div class={CtaRegresiveSt.h3}><h3>Horas</h3></div>
                        </div>
                    </div>
                    <div class={CtaRegresiveSt.responsivo2}>
                        <div class={CtaRegresiveSt.cartel}>
                            <div id="minutos"></div>
                            <div class={CtaRegresiveSt.h3}><h3>Minutos</h3></div>
                        </div>
                        <div class={CtaRegresiveSt.cartel}>
                            <div id="segundos"></div>
                            <div class={CtaRegresiveSt.h3}><h3>Segundos</h3></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}