import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setProyectSelect } from "../../Redux/Actions/index";
import BannerSlideM from "./BannerSlide.module.css";

export default function BannerSlide({ key, banner, img, h1, h3, btn, link, tipo, opacity, imgGig, imgTxt, img2 }) {

  const dispatch = useDispatch();

  // const ofertState = useSelector(state => state.ofertState);
  // console.log(img === undefined, 'iiiii')
  return (

    <div className={BannerSlideM.BannerSlide}>

      <div className={BannerSlideM.cardContent} >

        {

          h1?.length > 0 ? <h1>{h1}</h1> : null

        }

        {

          h3?.length > 0 ? <h3>{h3}</h3> : null

        }

        {

          btn?.length > 0 && <Link to={{
            pathname: `/Proyectos/${link}`,
            state: { tipo }
          }} ><div className={BannerSlideM.buttom} onClick={e => dispatch(setProyectSelect(tipo))} > <h3>{btn}</h3> </div></Link>

        }

      </div>

      {

        img != undefined &&

        <img className={BannerSlideM.imgStyle} src={img} alt="" />

      }

      {

        img === undefined &&

        <img className={BannerSlideM.imgStyle2} src={img2} alt="" />

      }

      <div className={BannerSlideM.boxAdi} >

        <div className={BannerSlideM.firstBox} >

          <img src={imgTxt} />

        </div>

        <div className={BannerSlideM.secondBox} >

          <img src={imgGig} />

        </div>

      </div>

    </div>

  );

}
