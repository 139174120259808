import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllProyect, getAllProducts, getProyectGoup, setOfertStatus, setFilterEnt, setProyectSelect, setFilterGroup } from "../../Redux/Actions/index";
import BtnVerMasConsultSt from "./BtnVerMasConsultSt.module.css";

export default function BtnVerMasConsult({pathname, tipo}) {

    const dispatch = useDispatch();

    const history = useHistory();

    return (

        <div className={BtnVerMasConsultSt.contentBTN} >

            <Link to={{
                pathname: pathname,
                state: { tipo }
            }} ><div className={BtnVerMasConsultSt.btnVer} onClick={e => { dispatch(setProyectSelect(tipo)) }} >{"VER TODOS"}</div></Link>

            <div className={BtnVerMasConsultSt.btnCon} onClick={e => history.push("/contactoTest")} >{"CONSULTAR"}</div>

        </div>

    )

}