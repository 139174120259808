export const GET_ALL_PROYECT = "GET_ALL_PROYECT";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PROYECT_GROUP = "GET_PROYECT_GROUP";
export const GET_ALL_ASPECONST = "GET_ALL_ASPECONST";
export const GET_ALL_ENTREGAS = "GET_ALL_ENTREGAS";
export const GET_FILTER_TYPE = "GET_FILTER_TYPE";
export const GET_FILTER_STATUS = "GET_FILTER_STATUS";
export const GET_ALL_BANER = "GET_ALL_BANER";
export const SET_OFERT_STATUS = "SET_OFERT_STATUS";
export const SET_FILTER_OFERT = "SET_FILTER_OFERT";
export const SET_STATUS_OFERT = "SET_STATUS_OFERT";
export const SET_FILTER_ENT = "SET_FILTER_ENT";
export const SET_PROYECT_SELECT = "SET_PROYECT_SELECT";