import React from 'react';
import { useSelector } from 'react-redux';
import BannerFinal from '../components/Banner/BannerFinal'
import BannerSlide from '../components/Banner/BannerSlide';

const BannerF = () => {

    const imgBannerDb = useSelector(state => state.banner);
    
    return (

        <div>

            <BannerFinal>

                {/* BANNER */}

                {

                    imgBannerDb[0]?.status === true &&

                    <BannerSlide

                        key={0}
                        id={0}
                        img2={imgBannerDb[0].img}
                        imgGig={imgBannerDb[0].imgGif}
                        imgTxt={imgBannerDb[0].imgTxt}

                    />

                }

                {/* BANNER */}

                <BannerSlide
                    key={1}
                    id={1}
                    h1="CASAS CONTAINER"
                    h3="¡Hacé realidad la tuya!"
                    // des={[10, 15, 20]}
                    btn="VER MAS"
                    img="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Fcasa-container-60-m2-premium-importainer-2.JPG?alt=media&token=14a03ba1-02e9-4479-b289-5a0ee0bbf4fa"
                    link="Casa Container"
                    tipo="casaContainer"
                />
                <BannerSlide
                    key={2}
                    id={2}
                    h1="DÚPLEX CONTAINER"
                    h3="¡Disfrutá tu casa container de dos pisos!"
                    // h12="hasta 240 cuotas y con entrega programada" 
                    //h4="CASAS, EXTERIORES, EMPRENDIMIENTOS Y OFICINAS AL CONTADO"
                    //h4FondNeg="Y HASTA 120 ó 240 CUOTAS"
                    btn="VER MAS"
                    img="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Fduplex-container-30-m2-classic-importainer-1.JPG?alt=media&token=1cc58d2c-da80-487b-80c3-fc48a7f555a0"
                    link="Duplex Container"
                    tipo="duplex"
                />
                <BannerSlide
                    key={3}
                    id={3}
                    h1="EXTERIORES CON CONTAINERS"
                    h3="¡Materializá la pileta, quincho, cochera y jardín de invierno que soñás!"
                    // h4="mejores planes de financiación del mercado"
                    // h4sub="¡COMPLETAMENTE FINANCIADO!"
                    // h4FondNeg="CONTADO Y HASTA 120 ó 240 CUOTAS"
                    btn="VER MAS"
                    img="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Fpileta-container-15-m2-classic-importainer-1.JPG?alt=media&token=6beb63df-38ef-44bf-b8e4-14c52c628679"
                    link="Exteriores de Ensueños"
                    tipo="exteriores"
                />
                <BannerSlide
                    key={4}
                    id={4}
                    // toph4="HOY PODÉS TENER TU PROPIA"
                    h1="COMBOS DE INMUEBLES CONTAINER"
                    h3="¡Construí tu hogar con todo lo que deseás!"
                    // h4="240 cuotas y con entrega programada!"
                    // h4sub="SI SOS EMPRENDEDOR O EMPRESARIO, ESCRIBINOS"
                    // h4FondNeg="FRANQUICIA@IMPORTAINER.COM.AR"
                    btn="VER MAS"
                    img="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Fduplex-container-45-m2-pileta-container-15-m2-quincho-container-15-m2-classic-importainer-2.JPG?alt=media&token=372791e7-6da2-447c-949c-647023b036e3"
                    link="Combo"
                    tipo="combo"
                />
                <BannerSlide
                    key={5}
                    id={5}
                    h1="OFICINAS CONTAINER"
                    h3="¡La solución perfecta para tu PyME o empresa grande!"
                    // h4sub="CON ENTREGA PROGRAMADA, AL CONTADO Y HASTA 240 CUOTAS"
                    btn="VER MAS"
                    img="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Foficina-container-75-m2-importainer-exterior.JPG?alt=media&token=afa77767-5497-4934-9179-4eec20d5d384"
                    link="Oficina Container"
                    tipo="oficina"
                />
                <BannerSlide
                    key={6}
                    id={6}
                    h1="BARES, FOOD-TRUCKS, ESTUDIOS, SALONES Y MUCHO MÁS CON CONTAINERS"
                    h3="¡Disfrutá tu inmueble a medida!"
                    // h4="BARES, FOODTRUCKS, ESTUDIOS DE FOTOGRAFIA Y MUCHO MAS"
                    // h4Blod2="!TUS SUEÑOS HECHOS REALIDAD EN UN CLICK!"
                    // h4sub="CON ENTREGA PROGRAMADA, AL CONTADO Y HASTA 240 CUOTAS"
                    btn="VER MAS"
                    img="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Ffood-truck-container-120-m2-classic-importainer-1.JPG?alt=media&token=e7d84d02-c7d2-4967-b2c5-bef9965608fb"
                    link="Emprendimientos"
                    tipo="emprendimiento"
                />
                <BannerSlide
                    key={7}
                    id={7}
                    h1="FRANQUICIAS IMPORTAINER"
                    h3="¡Beneficiate de nuestro sistema!"
                    // h4="¡TU ESPACIO DE TRABAJO A OJO Y SEMEJANZA!"
                    // h4sub="CON ENTREGA PROGRAMADA, AL CONTADO Y HASTA 240 CUOTAS"
                    // btn="DESCUBRI TODOS"
                    img="https://firebasestorage.googleapis.com/v0/b/base-datos-importaner.appspot.com/o/Banner%2Ffranquicia-importainer-interior.JPG?alt=media&token=bb5ac7b5-9efc-4f56-a188-633975314afd"
                // link="franquicias"
                // tipo="casaContainer"
                />
            </BannerFinal>

        </div>

    )
}

export default BannerF

