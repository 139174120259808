import React from "react";
import HeadBandList1St from "./HeadBandList1St.module.css";

export default function HeadBandList1({ list }) {

    return (

        <div className={HeadBandList1St.headBand} ><h3>{String(list).toUpperCase()}</h3></div>

    )

}