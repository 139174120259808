import React from "react";
import { Link } from "react-router-dom";
// import Cookies from "universal-cookie";
import AllEntregasCardSt from "./AllEntregasCardSt.module.css";

export default function AllEntregasCard({ id, urlYou, img, title, subTitle }) {

    // const cookies = new Cookies();
    // cookies.set("SameSite", "Lax", { secure: true, SameSite: 'none' });
    // cookies.set("SameSite", "Lax", { secure: true, SameSite: 'none' });

    // console.log(cookies, 'ppp')

    return (

        <Link to={`/Entregas/${id}`} >

            <div className={AllEntregasCardSt.bodyCard} >

                <div className={AllEntregasCardSt.contentVideo} >

                    <img src={img} />

                    {/* <iframe

                    width="100%"
                    height="100%"
                    src={urlYou}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>

                </iframe> */}

                    {/* <iframe

                    width="100%"
                    height="100%"
                    src={`${urlYou}?controls=0`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; 
                            autoplay; 
                            clipboard-write; 
                            encrypted-media; 
                            gyroscope; 
                            picture-in-picture; 
                            web-share"
                    allowfullscreen>

                </iframe> */}

                </div>

                <div className={AllEntregasCardSt.contentText} >

                    <h3>{title}</h3>

                    <p>{subTitle}</p>

                </div>

            </div>

        </Link>

    )

}