import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import Cookies from "universal-cookie";

const cookie = new Cookies();

if (cookie.get("fn") != undefined) {

  const res = cookie.get("fn");

  if (res.st) {

    const fech = new Date(new Date().setDate(new Date(res.da).getDate() + res.ts));

    cookie.set("fn", { "tg": res.tg, "ti": res.ti, "st": false, "da": `${fech.getDate() / fech.getMonth() / fech.getFullYear()}`, "ts": res.ts })

  } else {

    const dSt = new Date(String(res.da)).getDay();

    const d = new Date().getDate();

    if (dSt <= d) cookie.set("fn", { "tg": res.tg, "ti": res.ti, "st": true, "da": `${res.da}`, "ts": res.ts })

  }

} else {

  const res = cookie.get("fn");

  const d = new Date().getDate();

  const m = new Date().getMonth() + 1;

  const y = new Date().getFullYear();

  cookie.set("fn", { "tg": "html", "st": false, "da": `${d.toString()}/${m.toString()}/${y.toString()}`, "ts": 15 });

}

const persistConfig = {
  key: "root",
  debug: true,
  storage,
  whitelist: [

    "proyect",
    "allProducts",
    "proyectGroup",
    "ofertState",
    "ofertFilter",
    "aspeConst",
    "entregasFilter",
    "casaContainer",
    "duplex",
    "combo",
    "exteriores",
    "emprendimiento",
    "oficina",
    "proyectSelect",
    "productsFilterStatus",
    "classic",
    "comfort",
    "premium",

  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;