import React from "react";
import { Link } from "react-router-dom";
import FooterSt from "./FooterSt.module.css";

export default function Footer() {

    const footerActive = () => {

        document.getElementsByClassName(FooterSt.containerFooter).item(0).classList.toggle(FooterSt.footerActive)

        // console.log(document.getElementsByClassName(FooterSt.containerFooter), 'pppp')

    }

    return (

        <div className={FooterSt.contGralFooter} >

            <div className={FooterSt.containerFooter} >

                <div className={FooterSt.btnFooter} onClick={footerActive} >

                    {/* <div></div> */}

                </div>

                <div className={FooterSt.containerBodyF} >

                    <div className={FooterSt.social} >

                        <div className={FooterSt.icon} >

                            <a href="https://www.facebook.com/s.a.importainer?_rdc=1&_rdr" ><i className="fab fa-facebook-f"></i></a>

                        </div>

                        <div className={FooterSt.icon} >

                            <a href="https://www.instagram.com/importainer_sa/" ><i className="fab fa-instagram"></i></a>

                        </div>

                        <div className={FooterSt.icon} >

                            <a href="https://api.whatsapp.com/send/?phone=5491122763820&text&type=phone_number&app_absent=0" ><i className="fab fa-whatsapp"></i></a>

                        </div>

                        <div className={FooterSt.icon} >

                            <a href="https://www.linkedin.com/company/importainer-s-a/" ><i className="fab fa-linkedin-in"></i></a>

                        </div>

                        <div className={FooterSt.icon} >

                            <a href="https://www.youtube.com/c/ImportainerSA" ><i className="fab fa-youtube"></i></a>

                        </div>

                    </div>

                    <div className={FooterSt.containerLink} >

                        <ul>

                            <Link to="/" style={{ color: '#fff', textDecoration: 'none' }} >

                                <li><p>Home</p></li>

                            </Link>

                            <Link to="/proyectosNew" style={{ color: '#fff', textDecoration: 'none' }} >

                                <li><p>Proyectos</p></li>

                            </Link>

                            <Link to="/sistemaConstructivo" style={{ color: '#fff', textDecoration: 'none' }} >

                                <li><p>Sistema Constructivo</p></li>

                            </Link>

                            <Link to="/EntregasCategory" style={{ color: '#fff', textDecoration: 'none' }} >

                                <li><p>Entregas</p></li>

                            </Link>

                            <Link to="/contacto" style={{ color: '#fff', textDecoration: 'none' }} >

                                <li><p>Contacto</p></li>

                            </Link>

                        </ul>

                    </div>

                </div>

                <div className={FooterSt.contEmail} ><p>importainerr@gmail.com</p></div>

            </div>

        </div>

    )

}