import React from "react";
import HeadBandList2St from "./HeadBandList2St.module.css";

export default function HeadBandList2({ list }) {

    return (

        <div className={HeadBandList2St.headBand} ><h3>{String(list).toUpperCase()}</h3></div>

    )

}