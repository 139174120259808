import React, { useEffect, useRef, useState } from "react";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import BFSecond from "./BannerFinalSecond.module.css";

export default function BannerFinalSecond({ children, banner, arrLength, index }) {

    const slideshow = useRef(null);
    const intervaloSlideshow = useRef(null);
    const contenido = useRef(null);

    const [intervalActive, setIntervalActive] = useState(true);

    let g = 0;

    const Siguiente = () => {

        // Comprobamos que el slideshow tenga elementos
        if (slideshow.current?.children.length > 0) {

            // Establecemos la transicion para el slideshow
            slideshow.current.style.transition = `1500ms ease-out all`;

            const tamañoSlide = slideshow.current.children[0].offsetWidth;

            // Movemos el slideshow
            slideshow.current.style.transform = `translateX(-${tamañoSlide * g}px)`;

            g === (children.length - 1) ? g = 0 : g++;

        }
    };

    useEffect(() => {
        
        // slideNext()

        intervaloSlideshow.current = setInterval(() => {

            // next(1);

            // slideNext()

            if (intervalActive) {

                Siguiente()

            } 

        }, 3500);

        // Eliminamos el intervalo
        contenido.current.addEventListener("click", () => {

            clearInterval(intervaloSlideshow.current);
            
            setIntervalActive(false);

            // console.log('ggg')
            document.getElementsByClassName(BFSecond.stopSec).item(index).classList.add(BFSecond.stopSecActive);

            document.getElementsByClassName(BFSecond.arrowLeft).item(index).classList.add(BFSecond.ContentArrowFilter);

            document.getElementsByClassName(BFSecond.arrowRight).item(index).classList.add(BFSecond.ContentArrowFilter);

        });

        
        // document.getElementsByClassName(BFSecond.stopSec).item(index).addEventListener("mouseover", () => {

        //     document.getElementsByClassName(BFSecond.stopSec).item(index).classList.toggle((BFSecond.stopSecActive))

        // })
    }, [children]);
    // console.log(intervaloSlideshow,'iiiii')

    let j = 0;

    const next = (x) => {

        const eleContent = document?.getElementsByClassName(BFSecond.ContenedorSlideSecond)

        const cantElemt = children.length;

        const firstChildWidth = eleContent.item(index).offsetWidth;

        eleContent.item(index).style.transition = 'all 2s';

        if (x === 1) {

            if (j === (cantElemt - 1)) {

                eleContent.item(index).style.transform = `translateX(0px)`;

                j = 0

            } else {

                j++;

                eleContent.item(index).style.transform = `translateX(-${firstChildWidth * j}px)`;

            }

        } else if (x === 0) {

            j--;

            eleContent.item(index).style.transform = `translateX(-${firstChildWidth * j}px)`;

            if (j === -1) {

                eleContent.item(index).style.transform = `translateX(-${firstChildWidth * (cantElemt - 1)}px)`;

                j = cantElemt - 1;

            }

        }

    }

    return (

        <div className={BFSecond.BannerFinalSecond} ref={contenido}>

            <div className={BFSecond.stopSec} ><p>Detener Secuencia</p></div>

            <div className={BFSecond.arrowLeft} onClick={() => next(0)} ><ChangeHistoryIcon sx={{ color: '#B90000', fontSize: 50 }} /></div>

            <div className={BFSecond.arrowRight} onClick={() => next(1)} ><ChangeHistoryIcon sx={{ color: '#B90000', fontSize: 50 }} /></div>

            <div className={BFSecond.ContenedorSlideSecond} ref={slideshow}>

                {children}

            </div>

        </div>
    );
}
