import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NavBarSec from "../NavBarSec/NavBarSec";
import NavBarHover from "../NavBarHover/NavBarHover";
import ProyectosCards from "../ProyectosCards/ProyectosCards";
import AllEntregasCard from "../AllEntregasCard/AllEntregasCard";
import FooterSec from "../FooterSec/FooterSec";
import Footer from "../Footer/Footer";
import { getAllEntregas, setFilterEnt } from "../../Redux/Actions/index";
import AllEntregasSt from "./AllEntregasSt.module.css";

export default function AllEntregas() {

    const dispatch = useDispatch();

    const allEntregas = useSelector(state => state.allEntregas);

    useEffect(() => {

        dispatch(getAllEntregas());

    }, []);

    const disableIE = () => {

        if (document.all) {

            return false;

        }

    }

    const disableNS = (e) => {

        if (document.layers || (document.getElementById && !document.all)) {

            if (e.which==2 || e.which==3) {

                return false;

            }

        }

    }
    if (document.layers) {

        document.captureEvents(Event.MOUSEDOWN);

        document.onmousedown = disableNS;

    } else {

        document.onmouseup = disableNS;

        document.oncontextmenu = disableIE;

    }

    document.oncontextmenu = new Function("return false");

    // console.log(allEntregas, 'iiii');

    return (

        <>

            <NavBarSec />
            <NavBarHover />

            <h2 className={AllEntregasSt.titleStyle} >¡CONSTRUIMOS SUEÑOS!</h2>

            <div className={AllEntregasSt.contentEnt} >

                {

                    allEntregas.map((e, i) => {

                        // return <Link to={`/Entregas/${e.id}`} >

                        //     <AllEntregasCard

                        //         urlYou={e.urlYou}
                        //         title={e.title}
                        //         subTitle={e.subTitle}
                        //         key={i}

                        //     />

                        // </Link>

                        return <AllEntregasCard

                                id={e.id}
                                urlYou={e.urlYou}
                                img={e.img[0]}
                                title={e.title}
                                subTitle={e.subTitle}
                                key={i}

                            />

                    })

                }



            </div>

            {/* <ProyectosCards title={e.title} txtDesc={e.txtDetail} image={e.imgLink} tipo={e.tipo} /> */}

            {/* <FooterSec /> */}

            <Footer />

        </>

    )

}