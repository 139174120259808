import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import NavBarSec from '../NavBarSec/NavBarSec';
import BannerFinalSecond from '../Banner/BannerFinalSecond';
import BannerSlideSecond from '../Banner/BannerSlideSecond';

import BannerFinal from '../Banner/BannerFinal';
import BannerSlideFilterOff from '../Banner/bannerFilterOff/BannerSlideFilterOff';

import FooterSec from "../FooterSec/FooterSec";
import FooterPrinc from '../FooterPrinc/FooterPrinc';
import { setFilterEnt } from "../../Redux/Actions/index";
// import Entregas from '../Entregas/Entregas';
import Footer from '../Footer/Footer';
import EastIcon from '@mui/icons-material/East';
import EntDetSt from "./EntDescSt.module.css";

export default function EntDesc() {

    const entregasFilter = useSelector(state => state.entregasFilter);

    const dispatch = useDispatch();

    const params = useParams();

    useEffect(() => {

        dispatch(setFilterEnt(params.id))

    }, []);

    const disableIE = () => {

        if (document.all) {

            return false;

        }

    }

    const disableNS = (e) => {

        if (document.layers || (document.getElementById && !document.all)) {

            if (e.which == 2 || e.which == 3) {

                return false;

            }

        }

    }
    if (document.layers) {

        document.captureEvents(Event.MOUSEDOWN);

        document.onmousedown = disableNS;

    } else {

        document.onmouseup = disableNS;

        document.oncontextmenu = disableIE;

    }

    document.oncontextmenu = new Function("return false");

    // console.log(, 'iiiii')

    return (

        <div>

            <NavBarSec />

            <div className={EntDetSt.contentBannerTest} >

                {/* <BannerFinalSecond>

                    {

                        entregasFilter?.map(e => {

                            e.img.shift()

                            return e.img.map((i, j) => <BannerSlideSecond img={ i } key={j} /> )
                        
                        } )

                    }

                </BannerFinalSecond> */}

                <BannerFinal>

                    {

                        entregasFilter?.map(e => {

                            e.img.shift()

                            return e.img.map((i, j) => <BannerSlideFilterOff
                                key={j}
                                id={j}
                                img={i}

                            />)

                        })

                    }
                </BannerFinal>

            </div>

            <div className={EntDetSt.contentTiVi} >

                <h1>{entregasFilter[0]?.title1}</h1>

                <h3>{entregasFilter[0]?.title2}</h3>

                <div className={EntDetSt.contentView} >

                    <iframe

                        width="100%"
                        height="100%"
                        src={entregasFilter[0]?.urlYou}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>

                    </iframe>

                </div>

            </div>

            <div className={EntDetSt.txtGroup1} >

                {
                    String(entregasFilter[0]?.p1bl).length > 0 ?
                        <p><b>{entregasFilter[0]?.p1bl}</b> {entregasFilter[0]?.p1}</p> :
                        <p>{entregasFilter[0]?.p1}</p>
                }

                {
                    String(entregasFilter[0]?.p2bl).length > 0 ?
                        <p><b>{entregasFilter[0]?.p2bl}</b> {entregasFilter[0]?.p2}</p> :
                        <p>{entregasFilter[0]?.p2}</p>
                }

                {
                    String(entregasFilter[0]?.p3bl).length > 0 ?
                        <p><b>{entregasFilter[0]?.p3bl}</b> {entregasFilter[0]?.p3}</p> :
                        <p>{entregasFilter[0]?.p3}</p>
                }

                {
                    String(entregasFilter[0]?.p4bl).length > 0 ?
                        <p><b>{entregasFilter[0]?.p4bl}</b> {entregasFilter[0]?.p4}</p> :
                        <p>{entregasFilter[0]?.p4}</p>
                }

                {
                    String(entregasFilter[0]?.p5bl).length > 0 ?
                        <p><b>{entregasFilter[0]?.p5bl}</b> {entregasFilter[0]?.p5}</p> :
                        <p>{entregasFilter[0]?.p5}</p>
                }

                {
                    String(entregasFilter[0]?.p6bl).length > 0 ?
                        <p><b>{entregasFilter[0]?.p6bl}</b> {entregasFilter[0]?.p6}</p> :
                        <p>{entregasFilter[0]?.p6}</p>
                }

                {
                    String(entregasFilter[0]?.p7bl).length > 0 ?
                        <p><b>{entregasFilter[0]?.p7bl}</b> {entregasFilter[0]?.p7}</p> :
                        <p>{entregasFilter[0]?.p7}</p>
                }

            </div>

            <div className={EntDetSt.headFooter} >

                <div className={EntDetSt.linkForm} >

                    {/* {String(txt1).length > 0 ? <p>{txt1}</p> : <p>¡EMPEZÁ A CONSTRUIR TU SUEÑO!</p>} */}

                    <p>¡EMPEZÁ A CONSTRUIR TU SUEÑO!</p>

                    {/* <TelegramIcon sx={{color: '#FFF'}} />

    <Link to="/contactoTest"><p>¡EMPEZÁ A CONSTRUIR TU SUEÑO!</p></Link> */}

                </div>

                <div className={EntDetSt.contentFormFooter} >

                    <div className={EntDetSt.btnCtaFooter} >

                        <a href="https://wa.link/i1y028" >

                            {/* {String(txt2).length > 0 ? <p>{txt2}</p> : <p>¡SOLICITÁ TU PRESUPUESTO!</p>} */}

                            <p>¡SOLICITÁ TU PRESUPUESTO!</p>

                        </a>

                        <div className={EntDetSt.btnIcon} >

                            <EastIcon sx={{ color: '#FFF', fontSize: '30px' }} />

                        </div>

                    </div>

                    {/* <form className={footerStyle.formFooter} onSubmit={addContac} >

      <input type="number" name="number" placeholder="¡SOLICITA TU PRESUPUESTO!" onChange={e => numberChange(e)} />

      <input type="submit" />

    </form> */}

                </div>

            </div>

            {/* <FooterSec /> */}

            {/* <FooterPrinc /> */}

            <Footer />

        </div>

    )

}