import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBarPrinc from "../components/NavBarPrinc/NavBarPrinc";
import Wpp from "../components/wpp";
import BannerF from "./BannerF";
import ProductGroup from "../components/ProductGroup/ProductGroup";
import NavBarHover from "../components/NavBarHover/NavBarHover";
import Nosotros from "../components/Nosotros/Nosotros";
import Beneficios from "../components/Beneficios/Beneficios";
import Servicios from "../components/Servicios/Servicios";
import EntregasContainers from "../components/EntregasContainers/EntregasContainers";
import CtaRegresive from "../components/CtaRegresive/CtaRegresive";
import Footer from "../components/Footer/Footer";
import { getAllProyect, getAllProducts, getProyectGoup, getFilterType, setOfertFilter, setOfertStatus, getAllEntregas, getFilterStatus, getAllBaner } from "../Redux/Actions/index";
import HomeSt from "../components/Home/HomeSt.module.css";

const ContactoComplete = React.lazy(() =>
  import("../components/Contacto/ContactoComplete")
);
const FooterPrinc = React.lazy(() =>
  import("../components/FooterPrinc/FooterPrinc")
);

const Home = () => {

  // window.scroll(0,0);

  const dispatch = useDispatch();

  const allProducts = useSelector(state => state.allProducts);

  const ofertFilter = useSelector(state => state.ofertFilter);

  const casaContainer = useSelector(state => state.casaContainer);
  const duplex = useSelector(state => state.duplex);
  const combo = useSelector(state => state.combo);
  const exteriores = useSelector(state => state.exteriores);
  const emprendimientos = useSelector(state => state.emprendimientos);
  const oficina = useSelector(state => state.oficina);

  useEffect(() => {

    dispatch(getAllProyect());
    dispatch(getAllProducts());
    dispatch(setOfertFilter());
    dispatch(setOfertStatus(true));
    dispatch(getFilterType());
    dispatch(getAllEntregas());
    dispatch(getFilterStatus());
    dispatch(getAllBaner());

  }, []);

  const disableIE = () => {

      if (document.all) {

          return false;

      }

  }

  const disableNS = (e) => {

      if (document.layers || (document.getElementById && !document.all)) {

          if (e.which==2 || e.which==3) {

              return false;

          }

      }

  }
  if (document.layers) {

      document.captureEvents(Event.MOUSEDOWN);

      document.onmousedown = disableNS;

  } else {

      document.onmouseup = disableNS;

      document.oncontextmenu = disableIE;

  }

  document.oncontextmenu = new Function("return false");

  return (
    <div className={HomeSt.container} >
      <BannerF />
      <NavBarPrinc />
      <NavBarHover />
      <CtaRegresive />
      <ProductGroup navFoo={true} items={ofertFilter.sort((a, b) => a.orden - b.orden)} verMas={true} />
      <Nosotros />
      <Beneficios />
      <Servicios />
      <EntregasContainers />
      {/* El suspense es para que se renderice esa seccion solo cuando sea necesario */}
      <Suspense fallback={<div>Cargando...</div>}>
        <Footer />
        {/* <FooterPrinc /> */}
      </Suspense>
      <Wpp />
    </div>
  );
};

export default Home;
